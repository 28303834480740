import { MarketItem } from "./MarketItem";
import { Loan } from "../loan/Loan";
import { Address } from "viem";
import { Bid } from "./Bid";
import { MarketItemType } from "../../../contracts/MarketContract";
import { MarketItemFromServer } from "../../UnlockdService";
import TheGraph from "../../../thegraph";
import { INft } from "../nft/INft";

export type Attribute = {
  key: string;
  value: string;
  rarity: bigint;
  floorPrice: bigint;
};

export class MarketItemDetailed extends MarketItem {
  readonly attributes: Attribute[];

  constructor({
    assetId,
    bids,
    _doc,
    loanId,
    owner,
    status,
    type,
    id,
    lastStatusChangedTimestamp,
    nft,
    loan,
    attributes,
    reservoirBid,
    biddingEnd,
    minBid,
    _buyNowPrice,
  }: {
    loan: Loan;
    reservoirBid?: bigint | null;
    _buyNowPrice?: bigint;
    minBid?: bigint;
    nft: INft;
    type: MarketItemType;
    id?: Address;
    bids: Bid[];
    status: TheGraph.MarketItemStatus;
    biddingEnd: number;
    loanId: Address;
    assetId: Address;
    owner: Address;
    lastStatusChangedTimestamp?: number;
    _doc: TheGraph.MarketItem | MarketItemFromServer;
    attributes: Attribute[];
  }) {
    super({
      assetId,
      bids,
      _doc,
      loanId,
      owner,
      status,
      type,
      id,
      lastStatusChangedTimestamp,
      nft,
      loan,
      biddingEnd,
      reservoirBid,
      minBid,
      _buyNowPrice,
    });

    this.attributes = attributes;
  }

  get rarity(): bigint {
    return this.attributes.length > 0
      ? this.attributes.reduce((acc, { rarity }) => acc + rarity, BigInt(0)) /
      BigInt(this.attributes.length)
      : BigInt(0);
  }
}
