import {
  Address,
  OptionsWriteMethod,
  Output,
} from "@unlockdfinance/verislabs-web3";
import { IErc721Collection } from "../collection/ICollection";
import { MarketItemType } from "contracts/MarketContract";
import { IErc20Currency } from "../currency/ICurrency";
import { Bid } from "../marketplace/Bid";
import { WalletType } from "contracts/types";
import { Attribute } from "./Attribute";

export enum OwnerType {
  UNLOCKD = "u-wallet",
  EXTERNAL = "ext-wallet",
  OTHER = "other",
}

export interface SimpleNft {
  collection: Address;
  tokenId: string;
}

export interface NftWithImage extends SimpleNft {
  image: string;
}

export interface NftWithImageAndDepositedFlag extends NftWithImage {
  attributes: Attribute[];
  isDeposited: boolean;
  owner: Address;
  underlyingAsset: Address;
}

export interface NftWithImageAndName extends NftWithImage {
  name: string;
}

export type MarketItemData = {
  id: Address;
  biddingEnd: number;
  type: MarketItemType;
  bids: Bid[];
};

export interface INft extends NftWithImage {
  readonly _collection: IErc721Collection;
  readonly tokenId: string;
  readonly image: string;
  readonly valuation: bigint;
  readonly ltv: bigint;
  readonly owner: Address;
  readonly liquidationThreshold: bigint;
  readonly availableToBorrow: bigint;
  readonly isDeposited: boolean;

  readonly nftId: string;
  readonly assetId: Address;
  readonly name: string;
  readonly collection: Address;
  readonly alt: string;
  readonly isCryptopunk: boolean;
  readonly isErc721: boolean;
  readonly ownerType: OwnerType;
  readonly marketItemData?: MarketItemData;
  readonly currency: IErc20Currency;
  readonly isListed: boolean;
  readonly isCancelListAvailable: boolean;
  readonly nameToShow: string;
  readonly notValuated: boolean;
  readonly attributes: Attribute[];

  title: Promise<string>;
  getReservoirBid: () => Promise<{
    amount: bigint;
    netAmount: bigint;
    id: string;
  } | null>;
  getValuationInUsd: () => Promise<bigint>;
  getAvToBorrowInUsd: () => Promise<bigint>;

  transfer: (
    from: Address,
    to: Address,
    options?: OptionsWriteMethod,
    walletType?: WalletType
  ) => Promise<Output<void>>;
}
