import { Address } from "viem";
import TheGraph from "../../thegraph";

export default async function getLoansOfAddress(
  address: Address,
  loanStatus: TheGraph.LoanStatus
) {
  const { accounts } = await TheGraph.client.getLoansOfAddress({
    user: address,
    status: loanStatus,
  });

  if (!accounts || !accounts.length) return [];

  return accounts[0].borrows;
}
