import { SimpleMarketItem } from "./SimpleMarketItem";
import { MarketItemType } from "../../../contracts/MarketContract";
import { Address } from "viem";
import { Bid } from "./Bid";
import TheGraph from "../../../thegraph";
import { externalWalletModule } from "../../../clients/verisModule";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";
import { NftWithImageAndName } from "../nft/INft";
import { HistoryItemType } from "./TransactionHistoryItem";

export const enum MyActivityItemStatus {
  // winning and still pending to finish
  ONGOING,
  // auction finished and lost
  LOST,
  // the user has placed a bid and someone else bid over, but still pending to finish
  BID_FAILED,
  SOLD,
  CLAIM,
  CLAIMED,
  REDEEMED,
  CANCELLED,
}

export class MyActivityItem extends SimpleMarketItem<NftWithImageAndName> {
  soldPrice: bigint;
  latestBid: bigint;
  buyer?: Address;

  constructor({
    nft,
    assetId,
    biddingEnd,
    bids,
    id,
    loanId,
    owner,
    status,
    type,
    doc,
    lastStatusChangedTimestamp,
    latestBid,
    soldPrice,
    buyer,
  }: {
    nft: NftWithImageAndName;
    type: MarketItemType;
    id: Address;
    bids: Bid[];
    status: TheGraph.MarketItemStatus;
    biddingEnd: number;
    loanId: Address;
    assetId: Address;
    owner: Address;
    lastStatusChangedTimestamp: number;
    soldPrice: bigint;
    latestBid: bigint;
    buyer: Address;
    doc: TheGraph.MarketItem;
  }) {
    super({
      nft,
      assetId,
      biddingEnd,
      bids,
      loanId,
      owner,
      status,
      type,
      _doc: doc,
      id,
      lastStatusChangedTimestamp,
    });

    this.soldPrice = soldPrice;
    this.latestBid = latestBid;
    this.buyer = buyer;
  }

  get wasSold(): boolean {
    return this.status === TheGraph.MarketItemStatus.BOUGHT;
  }

  get price() {
    return this.wasSold ? this.soldPrice : this.latestBid;
  }

  get myActivityStatus(): MyActivityItemStatus {
    if (!externalWalletModule.address) {
      throw new Error("to get the status some address should be connected");
    }

    if (this.status === TheGraph.MarketItemStatus.REDEEMED) {
      return MyActivityItemStatus.REDEEMED;
    } else if (this.status === TheGraph.MarketItemStatus.BOUGHT) {
      if (equalIgnoreCase(this.buyer!, externalWalletModule.address)) {
        return MyActivityItemStatus.SOLD;
      } else {
        return MyActivityItemStatus.LOST;
      }
    } else if (this.status === TheGraph.MarketItemStatus.CLAIMED) {
      return MyActivityItemStatus.CLAIMED;
    } else if (
      this.status === TheGraph.MarketItemStatus.CANCELLED ||
      this.status === TheGraph.MarketItemStatus.PAID
    ) {
      return MyActivityItemStatus.CANCELLED;
    } else if (this.status === TheGraph.MarketItemStatus.ACTIVE) {
      if (
        this.bidder &&
        equalIgnoreCase(this.bidder, externalWalletModule.address)
      ) {
        if (this.hasAuctionFinished) {
          return MyActivityItemStatus.CLAIM;
        } else {
          return MyActivityItemStatus.ONGOING;
        }
      } else {
        if (this.hasAuctionFinished) {
          return MyActivityItemStatus.LOST;
        } else {
          return MyActivityItemStatus.BID_FAILED;
        }
      }
    } else {
      throw new Error("status not supported");
    }
  }

  get historyType(): HistoryItemType {
    return this.myActivityStatus === MyActivityItemStatus.SOLD
      ? HistoryItemType.PURCHASE
      : HistoryItemType.BID;
  }
}
