import { alchemy } from "../../clients";
import { AssetTransfersCategory, SortingOrder } from "alchemy-sdk";
import { Address } from "viem";
import { Hash } from "@unlockdfinance/verislabs-web3";
import { app } from "app.config";

export interface ITransaction {
  hash: Hash;
  status?: number;
  date?: Date;
}

const faucetAddresses = app.FAUCET_COLLECTIONS.map(({ address }) => address);

export const retrieveMintHistoryOfAddress = async (
  address: Address
): Promise<ITransaction[]> => {
  const res = await alchemy.core.getAssetTransfers({
    fromBlock: "0x0",
    fromAddress: "0x0000000000000000000000000000000000000000",
    toAddress: address,
    contractAddresses: faucetAddresses,
    excludeZeroValue: true,
    category: [AssetTransfersCategory.ERC721],
    order: SortingOrder.DESCENDING,
    withMetadata: true,
  });

  const transfers = res.transfers.map((transfer) => {
    return {
      hash: transfer.hash as Hash,
      date: new Date(transfer.metadata.blockTimestamp),
      status: 1,
    };
  });

  return transfers.filter((transfer, index, array) => {
    return (
      array.findIndex((_transfer) => _transfer.hash === transfer.hash) === index
    );
  });
};
