import { FC } from "react";
import Image from "next/image";
import classNames from "classnames";
import { useCustomWallet } from "components/providers";
import { OwnerType } from "logic/types/nft/INft";

type Props = {
  className: string;
  src: string;
  alt: string;
  depositedTag?: boolean;
  walletImage?: OwnerType;
};

const NftImage: FC<Props> = ({
  className,
  depositedTag = false,
  walletImage = false,
  ...restProps
}) => {
  const { connector } = useCustomWallet();

  const isClouldflareSrc = restProps.src.startsWith(
    "https://cloudflare-ipfs.com"
  );

  const imageLoader = !isClouldflareSrc
    ? undefined
    : isClouldflareSrc
    ? ({}: { src: string; width: number; quality?: number }) => {
        return restProps.src;
      }
    : undefined;

  return (
    <div className={classNames(className, "relative overflow-hidden grid")}>
      {depositedTag && (
        <div className="col-start-1 row-start-1 z-20 flex justify-end">
          <div className="h-fit w-fit mt-2 mr-2 px-3 bg-gradient-to-r from-gray-500 to-gray-700 rounded-xl border-2 border-green-400 font-semibold">
            Deposited
          </div>
        </div>
      )}
      {walletImage === OwnerType.UNLOCKD && (
        <Image
          className="absolute bottom-0.5 right-0.5 z-20"
          src="/assets/images/unlockd-wallet-icon.png"
          width={35}
          height={35}
          alt="unlockd wallet"
        />
      )}
      {walletImage === OwnerType.EXTERNAL && connector?.icon && (
        <div className="absolute bottom-0.5 right-0.5 z-20 h-[35px] w-[35px] bg-white rounded-lg p-1 flex items-center justify-center">
          <Image
            src={connector.icon}
            alt={`${connector.name} wallet`}
            width={32}
            height={32}
          />
        </div>
      )}
      <Image
        className={classNames(
          "object-cover h-full",
          depositedTag && "col-start-1 row-start-1 z-10 grayscale"
        )}
        fill
        sizes="30vw"
        loader={imageLoader}
        {...restProps}
      />
    </div>
  );
};

export default NftImage;
