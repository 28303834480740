import retrieveLockeysHolderType from "./retrieveLockeysHolderType";
import isWeb3InteractionsAvailableForUser from "./isWeb3InteractionsAvailableForUser";
export { getAllowance } from "./getAllowance";
export { approveErc20 } from "./approveErc20";

const logic = {
  retrieveLockeysHolderType,
  isWeb3InteractionsAvailableForUser,
};

export default logic;
