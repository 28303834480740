import { Address } from "viem";
import { MarketItemType } from "../../../contracts/MarketContract";
import { auctionContract, marketContract } from "../../../contracts";
import { Bid } from "./Bid";
import unlockdService, { MarketItemFromServer } from "../../UnlockdService";
import TheGraph from "../../../thegraph";
import unlockdWalletModule from "../../UnlockdWalletModule";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import { externalWalletModule } from "../../../clients/verisModule";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";
import { app } from "app.config";
import currenciesModule from "logic/CurrenciesModule";
import { NftWithImageAndName } from "../nft/INft";

export type MarketItemOptions = { newBid?: Bid };

export class SimpleMarketItem<TNft extends NftWithImageAndName> {
  readonly _doc?: TheGraph.MarketItem | MarketItemFromServer;
  readonly type: MarketItemType;
  readonly id?: Address;
  readonly nft: TNft;
  readonly bids: Bid[];
  readonly latestBid: bigint | null;
  readonly bidder: Address | null;
  readonly biddingEnd: number;
  readonly status?: TheGraph.MarketItemStatus;
  readonly lastStatusChangedTimestamp?: number;
  readonly loanId: Address;
  readonly owner: Address;
  readonly assetId: Address;

  constructor({
    nft,
    assetId,
    biddingEnd,
    bids,
    id,
    loanId,
    status,
    type,
    lastStatusChangedTimestamp,
    owner,
    _doc,
    options,
  }: {
    nft: TNft;
    type: MarketItemType;
    id?: Address;
    bids: Bid[];
    status: TheGraph.MarketItemStatus;
    biddingEnd: number;
    loanId: Address;
    assetId: Address;
    owner: Address;
    lastStatusChangedTimestamp?: number;
    _doc?: any;
    options?: MarketItemOptions;
  }) {
    this._doc = _doc;
    this.type = type;
    this.id = id;
    this.nft = nft;
    this.bids = bids;
    this.latestBid = this.bids?.at(-1)?.amount || null;
    this.bidder = this.bids?.at(-1)?.bidder || null;
    this.biddingEnd = biddingEnd;
    this.status = status;
    this.loanId = loanId;
    this.assetId = assetId;
    this.owner = owner;

    this.lastStatusChangedTimestamp = lastStatusChangedTimestamp;

    if (options?.newBid) {
      this.bids.push(options.newBid);
    }
  }

  private get currenciesAddressSupported() {
    return app.COLLECTIONS.find(({ address }) =>
      equalIgnoreCase(address, this.nft.collection)
    )!.currenciesSupported.map(({ address }) => address);
  }

  get currency() {
    const currencyAddressSupported = this.currenciesAddressSupported[0];

    const currency = currenciesModule.getErc20CurrencyByAddress(
      currencyAddressSupported
    )!;

    return currency;
  }

  get key(): string {
    return this.id || this.nft.collection + this.nft.tokenId;
  }

  get isOwnDebt(): boolean {
    return externalWalletModule.address != undefined
      ? equalIgnoreCase(externalWalletModule.address, this.owner)
      : false;
  }

  get hasAuctionFinished(): boolean {
    // if (!this.isAuctionAvailable) {
    //   throw new Error("Auction is not available");
    // }

    return this.biddingEnd! <= Date.now();
  }

  get isAuctionAvailable(): boolean {
    return this.type !== MarketItemType.TYPE_FIXED_PRICE;
  }

  get isItemAuctioned(): boolean {
    return this.type === MarketItemType.TYPE_LIQUIDATION_AUCTION;
  }

  get isItemListed(): boolean {
    return this.type !== MarketItemType.TYPE_LIQUIDATION_AUCTION;
  }

  get hasBuyNowAsOption(): boolean {
    return (
      this.type !== MarketItemType.TYPE_AUCTION &&
      this.type !== MarketItemType.TYPE_LIQUIDATION_AUCTION
    );
  }

  async claim(options?: OptionsWriteMethod): Promise<Output<void>> {
    this.validateId();

    const debtOfBid = this.bids.at(-1)?.amountOfDebt;

    const hasBidWithDebt = debtOfBid && debtOfBid > BigInt(0);

    const onUWallet = hasBidWithDebt || !!unlockdWalletModule.unlockdAddress;

    options?.onServerSignPending?.();

    if (this.isItemListed) {
      const { data, signature } = await unlockdService.getMarketSignature(
        this.nft
      );

      return marketContract.claim(
        onUWallet,
        this.id!,
        data,
        signature,
        options
      );
    } else {
      const { data, signature } = await unlockdService.getAuctionClaimSignature(
        this.loanId,
        this.nft
      );

      return auctionContract.finalize(
        onUWallet,
        this.id!,
        this.nft,
        data,
        signature,
        options
      );
    }
  }

  protected validateId() {
    if (!this.id) {
      throw new Error("Market item id is not defined");
    }
  }
}
