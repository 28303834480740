import { app } from "app.config";
import { LockeysHolderType } from "./types";

const DATE_TEAM = app.DATE_TO_FAUCET_TEAM;
const DATE_ONE_ONE_ONE = app.DATE_TO_FAUCET_ONE_ON_ONE;
const DATE_KEY_SUPPORTER = app.DATE_TO_FAUCET_KEY_SUPPORTER;
const DATE_HOLDER = app.DATE_TO_FAUCET_HOLDER;
const DATE_NON_HOLDER = app.DATE_TO_FAUCET_NON_HOLDER;

const isWeb3InteractionsAvailableForUser = (
  lockeysHolderType: LockeysHolderType
): boolean => {
  const now = Date.now();

  if (now >= new Date(DATE_NON_HOLDER).getTime()) {
    return true;
  } else if (
    now >= new Date(DATE_HOLDER).getTime() &&
    lockeysHolderType !== LockeysHolderType.NON_HOLDER
  ) {
    return true;
  } else if (
    now >= new Date(DATE_KEY_SUPPORTER).getTime() &&
    lockeysHolderType !== LockeysHolderType.NON_HOLDER &&
    lockeysHolderType !== LockeysHolderType.HOLDER
  ) {
    return true;
  } else if (
    now >= new Date(DATE_ONE_ONE_ONE).getTime() &&
    (lockeysHolderType === LockeysHolderType.ONE_ON_ONE ||
      lockeysHolderType === LockeysHolderType.TEAM)
  ) {
    return true;
  } else if (
    now >= new Date(DATE_TEAM).getTime() &&
    lockeysHolderType === LockeysHolderType.TEAM
  ) {
    return true;
  }

  return false;
};

export default isWeb3InteractionsAvailableForUser;
