import { determineAuctionStatus } from "../../helpers";
import { SimpleMarketItem } from "./SimpleMarketItem";
import { Loan } from "../loan/Loan";
import { auctionContract, marketContract } from "../../../contracts";
import { MarketItemType } from "../../../contracts/MarketContract";
import { Address } from "viem";
import { Bid } from "./Bid";
import unlockdService, { MarketItemFromServer } from "../../UnlockdService";
import { encodeEventTopics, decodeEventLog } from "viem";
import TheGraph from "../../../thegraph";
import unlockdWalletModule from "../../UnlockdWalletModule";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import { externalWalletModule } from "../../../clients/verisModule";
import { areTheSameNft } from "@unlockdfinance/verislabs-web3/utils";
import { INft } from "../nft/INft";
import { calculateLoanValuation, calculateLtv } from "logic/helpers/math";

export enum MarketItemAuctionStatus {
  TO_REPAY,
  TO_REDEEM,
  TO_CLAIM,
  TO_FIRST_BID,
  TO_BID,
  BIDDED,
  REDEEM_FINISHED,
  BID_FINISHED,
  LOADING_CLAIM,
  CLAIMED,
}

export type DataToRedeem = {
  maxRedeem: bigint;
  minRedeem: bigint;
  bidFine: bigint;
  liquidationThreshold: bigint;
};

export type DataToRepay = {
  apy: number;
  liquidationThreshold: bigint;
};

export class MarketItem extends SimpleMarketItem<INft> {
  static AuctionStatus = MarketItemAuctionStatus;

  private _buyNowPrice?: bigint;
  readonly loan: Loan;
  readonly minBid?: bigint;
  readonly _doc: TheGraph.MarketItem | MarketItemFromServer;
  readonly reservoirBid?: bigint | null;
  readonly status: TheGraph.MarketItemStatus;

  constructor({
    assetId,
    biddingEnd,
    bids,
    id,
    lastStatusChangedTimestamp,
    loanId,
    owner,
    status,
    type,
    _doc,
    nft,
    loan,
    reservoirBid,
    minBid,
    _buyNowPrice,
  }: {
    loan: Loan;
    reservoirBid?: bigint | null;
    _buyNowPrice?: bigint;
    minBid?: bigint;
    nft: INft;
    type: MarketItemType;
    id?: Address;
    bids: Bid[];
    status: TheGraph.MarketItemStatus;
    biddingEnd: number;
    loanId: Address;
    assetId: Address;
    owner: Address;
    lastStatusChangedTimestamp?: number;
    _doc: MarketItemFromServer | TheGraph.MarketItem;
  }) {
    super({
      nft,
      type,
      assetId,
      biddingEnd,
      bids,
      id,
      lastStatusChangedTimestamp,
      loanId,
      owner,
      status,
      _doc,
    });

    this._doc = _doc;
    this.status = status;
    this.loan = loan;
    this.reservoirBid = reservoirBid;
    this.minBid = minBid;
    this._buyNowPrice = _buyNowPrice;
  }

  get auctionStatus(): MarketItemAuctionStatus | null {
    return determineAuctionStatus(
      this.type,
      this.loan.borrower,
      externalWalletModule.address,
      this.biddingEnd!,
      this.bidder,
      !!this.bidder
    );
  }

  get isBuyNowAvailable(): boolean {
    const auctionStatus = this.auctionStatus;

    return (
      this.hasBuyNowAsOption &&
      !this.isOwnDebt &&
      !this.hasAuctionFinished &&
      auctionStatus !== MarketItemAuctionStatus.TO_REDEEM &&
      auctionStatus !== MarketItemAuctionStatus.TO_REPAY &&
      auctionStatus !== MarketItemAuctionStatus.CLAIMED &&
      auctionStatus !== MarketItemAuctionStatus.TO_CLAIM
    );
  }

  get futureNfts(): INft[] {
    return this.loan.nfts.filter((nft) => !areTheSameNft(nft, this.nft));
  }

  get aggLoanPrice(): bigint {
    return this.futureNfts.length > 0
      ? calculateLoanValuation(this.futureNfts)
      : BigInt(0);
  }

  get aggLtv(): bigint {
    return this.futureNfts.length > 0
      ? calculateLtv(this.futureNfts)
      : BigInt(0);
  }

  // THE BUY NOW PRICE THAT IS RETURNED WILL BE THE VALUE RETURNED BY THE CONTRACT
  // WITH A 1% INCREASE TO BE SURE THE IT COVERS THE DEBT
  get buyNowPrice(): bigint | undefined {
    if (this._buyNowPrice) {
      return (this._buyNowPrice * BigInt(101)) / BigInt(100);
    }
  }

  getMinBidPriceAsync() {
    if (this.isItemListed) {
      this.validateId();

      return marketContract.getMinBidPrice(
        this.id!,
        this.loan.underlyingAsset,
        this.aggLoanPrice,
        this.aggLtv
      );
    } else {
      return auctionContract.getMinBidPriceAuction(
        this.loan.id,
        this.assetId,
        this.nft.valuation,
        this.aggLoanPrice,
        this.aggLtv
      );
    }
  }

  calculateMinInitialPayment(
    bidOrBuyAmount: bigint
    // isBid: boolean = false
  ): bigint {
    const minInitialPayment =
      bidOrBuyAmount - (this.nft.valuation * this.nft.ltv) / BigInt(10000);

    const correctedMinPayment = (minInitialPayment * BigInt(101)) / BigInt(100);

    return minInitialPayment < BigInt(0)
      ? BigInt(0)
      : correctedMinPayment > bidOrBuyAmount
      ? bidOrBuyAmount
      : // WE INCREASE THE MINIMUM AMOUNT TO PAY 1%
        // TO BE SURE THAT AT THE MOMENT OF THE CLAIM
        // THE FUTURE DEBT WOULD BE HEALTHY
        correctedMinPayment;
  }

  async placeABid(
    amountToPay: bigint,
    amountOfDebt: bigint,
    options: OptionsWriteMethod
  ): Promise<Address> {
    if (!this.isAuctionAvailable) {
      throw new Error("This item does not support bids");
    } else if (this.hasAuctionFinished) {
      throw new Error("This auction has finished");
    }

    if (this.isItemListed) {
      this.validateId();

      options?.onServerSignPending?.();

      const { data, signature } = await unlockdService.getMarketSignature(
        this.nft
      );

      options.topicFilter = encodeEventTopics({
        abi: marketContract.abi,
        eventName: "MarketBid",
      })[0];

      const output = await marketContract.bid(
        this.id!,
        amountToPay,
        amountOfDebt,
        data,
        signature,
        options
      );

      if (!output.log) {
        throw new Error("log not found");
      }

      const topics = decodeEventLog({
        abi: marketContract.abi,
        data: output.log.data,
        topics: output.log.topics,
        eventName: "MarketBid",
      });

      return topics.args.orderId;
    } else {
      options?.onServerSignPending?.();

      const { data, signature } =
        await unlockdService.getBidOnAuctionedSignature(this.loan.id, this.nft);

      options.topicFilter = encodeEventTopics({
        abi: auctionContract.abi,
        eventName: "AuctionBid",
      })[0];

      const output = await auctionContract.bid(
        amountToPay,
        amountOfDebt,
        data,
        signature,
        options
      );

      if (!output.log) {
        throw new Error("log not found");
      }

      const topics = decodeEventLog({
        abi: auctionContract.abi,
        data: output.log.data,
        topics: output.log.topics,
        eventName: "AuctionBid",
      });

      return topics.args.orderId;
    }
  }

  async buyNow(
    amountToPay: bigint,
    amountOfDebt: bigint,
    claimOnUWallet: boolean,
    options: OptionsWriteMethod
  ): Promise<Output<void>> {
    this.validateId();

    if (!claimOnUWallet && amountOfDebt > BigInt(0)) {
      throw new Error(
        "Must claim on uWallet because debt is related to the transaction"
      );
    } else if (claimOnUWallet && !unlockdWalletModule.unlockdAddress) {
      throw new Error("UWallet not detected on this user");
    }

    options?.onServerSignPending?.();

    const { data, signature } = await unlockdService.getMarketSignature(
      this.nft
    );

    return marketContract.buyNow(
      this.id!,
      amountToPay,
      amountOfDebt,
      claimOnUWallet,
      data,
      signature,
      options
    );
  }
}
