import { externalWalletModule } from "../../clients/verisModule";
import { lockeysContract } from "../../contracts";
import { Address } from "viem";

export const isLockeysHolder = async (
  address: Address = externalWalletModule.address as Address
): Promise<boolean> => {
  // console.log(lockeysContract);

  // const balance = await lockeysContract.balanceOf(address);

  // return Number(balance) !== 0;

  return false;
};
