import { isSomeInRange } from "../utils";
import { parseEnvRanges } from "../utils";
import { alchemy } from "../clients";
import { LockeysHolderType } from "./types";
import { app } from "app.config";

const RANGES_ONE_ON_ONE = parseEnvRanges(app.TOKEN_ID_RANGES_ONE_ON_ONE!);
const RANGES_TEAM = parseEnvRanges(app.TOKEN_ID_RANGES_TEAM!);
const RANGES_KEY_SUPPORTER = parseEnvRanges(app.TOKEN_ID_RANGES_KEY_SUPPORTER!);

type fun = (address: string) => Promise<LockeysHolderType>;

const retrieveLockeysHolderType: fun = async (address) => {
  //@ts-ignore
  const nftsForOwner = await alchemy.TEMP_MAINNET.nft.getNftsForOwner(address, {
    contractAddresses: [app.CONTRACT_ADDRESSES.theLockeys],
  });
  //@ts-ignore
  const tokenIds = nftsForOwner.ownedNfts.map((nft) => parseInt(nft.tokenId));

  let lockeysHolderType: LockeysHolderType;

  if (isSomeInRange(tokenIds, RANGES_TEAM)) {
    lockeysHolderType = LockeysHolderType.TEAM;
  } else if (isSomeInRange(tokenIds, RANGES_ONE_ON_ONE)) {
    lockeysHolderType = LockeysHolderType.ONE_ON_ONE;
  } else if (isSomeInRange(tokenIds, RANGES_KEY_SUPPORTER)) {
    lockeysHolderType = LockeysHolderType.KEY_SUPPORTER;
  } else if (tokenIds.length > 0) {
    lockeysHolderType = LockeysHolderType.HOLDER;
  } else {
    lockeysHolderType = LockeysHolderType.NON_HOLDER;
  }

  return lockeysHolderType;
};

export default retrieveLockeysHolderType;
