import { getBuiltGraphSDK } from "../.graphclient";
import { AsyncReturnType } from "../logic/types/AsyncReturnType";

namespace TheGraph {
  export enum LoanStatus {
    BORROWED = "0",
    PENDING = "1",
    PAID = "2",
  }

  export type Loan = NonNullable<
    AsyncReturnType<typeof client.getLoanById>["loan"]
  >;

  export enum MarketItemStatus {
    ACTIVE = "0",
    CANCELLED = "1",
    REDEEMED = "2",
    BOUGHT = "3",
    CLAIMED = "4",
    PAID = "5",
  }

  export type BidFromSubgraph = NonNullable<
    AsyncReturnType<typeof client.getMarketItem>["order"]
  >["bids"][0];

  export type MarketItem = NonNullable<
    AsyncReturnType<typeof client.getMarketItem>["order"]
  >;

  export const client = getBuiltGraphSDK();
}

export default TheGraph;
