// @ts-nocheck
import { GraphQLResolveInfo, SelectionSetNode, FieldNode, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '@graphql-mesh/utils';

import type { GetMeshOptions } from '@graphql-mesh/runtime';
import type { YamlConfig } from '@graphql-mesh/types';
import { PubSub } from '@graphql-mesh/utils';
import { DefaultLogger } from '@graphql-mesh/utils';
import MeshCache from "@graphql-mesh/cache-localforage";
import { fetch as fetchFn } from '@whatwg-node/fetch';

import { MeshResolvedSource } from '@graphql-mesh/runtime';
import { MeshTransform, MeshPlugin } from '@graphql-mesh/types';
import GraphqlHandler from "@graphql-mesh/graphql"
import BareMerger from "@graphql-mesh/merger-bare";
import { printWithCache } from '@graphql-mesh/utils';
import { createMeshHTTPHandler, MeshHTTPHandler } from '@graphql-mesh/http';
import { getMesh, ExecuteMeshFn, SubscribeMeshFn, MeshContext as BaseMeshContext, MeshInstance } from '@graphql-mesh/runtime';
import { MeshStore, FsStoreStorageAdapter } from '@graphql-mesh/store';
import { path as pathModule } from '@graphql-mesh/cross-helpers';
import { ImportFn } from '@graphql-mesh/types';
import type { Unlockdv2SepoliTypes } from './sources/unlockdv2_sepoli/types';
import * as importedModule$0 from "./sources/unlockdv2_sepoli/introspectionSchema";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };



/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
  Int8: any;
};

export type Account = {
  id: Scalars['ID'];
  user: Scalars['Bytes'];
  amountDeposited: Scalars['BigInt'];
  amountBorrowed: Scalars['BigInt'];
  totalAssets: Scalars['BigInt'];
  borrows: Array<Loan>;
};


export type AccountborrowsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Loan_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Loan_filter>;
};

export type Account_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  amountDeposited?: InputMaybe<Scalars['BigInt']>;
  amountDeposited_not?: InputMaybe<Scalars['BigInt']>;
  amountDeposited_gt?: InputMaybe<Scalars['BigInt']>;
  amountDeposited_lt?: InputMaybe<Scalars['BigInt']>;
  amountDeposited_gte?: InputMaybe<Scalars['BigInt']>;
  amountDeposited_lte?: InputMaybe<Scalars['BigInt']>;
  amountDeposited_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountDeposited_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountBorrowed?: InputMaybe<Scalars['BigInt']>;
  amountBorrowed_not?: InputMaybe<Scalars['BigInt']>;
  amountBorrowed_gt?: InputMaybe<Scalars['BigInt']>;
  amountBorrowed_lt?: InputMaybe<Scalars['BigInt']>;
  amountBorrowed_gte?: InputMaybe<Scalars['BigInt']>;
  amountBorrowed_lte?: InputMaybe<Scalars['BigInt']>;
  amountBorrowed_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountBorrowed_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalAssets?: InputMaybe<Scalars['BigInt']>;
  totalAssets_not?: InputMaybe<Scalars['BigInt']>;
  totalAssets_gt?: InputMaybe<Scalars['BigInt']>;
  totalAssets_lt?: InputMaybe<Scalars['BigInt']>;
  totalAssets_gte?: InputMaybe<Scalars['BigInt']>;
  totalAssets_lte?: InputMaybe<Scalars['BigInt']>;
  totalAssets_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalAssets_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  borrows_?: InputMaybe<Loan_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Account_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Account_filter>>>;
};

export type Account_orderBy =
  | 'id'
  | 'user'
  | 'amountDeposited'
  | 'amountBorrowed'
  | 'totalAssets'
  | 'borrows';

export type ActiveVault = {
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
};

export type ActiveVault_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isActive_not?: InputMaybe<Scalars['Boolean']>;
  isActive_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isActive_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ActiveVault_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ActiveVault_filter>>>;
};

export type ActiveVault_orderBy =
  | 'id'
  | 'isActive'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash';

export type AddCollateral = {
  id: Scalars['ID'];
  loanId: Scalars['Bytes'];
  collection: Scalars['Bytes'];
  tokenId: Scalars['BigInt'];
  assetId: Scalars['Bytes'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionInput: Scalars['Bytes'];
};

export type AddCollateral_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  collection?: InputMaybe<Scalars['Bytes']>;
  collection_not?: InputMaybe<Scalars['Bytes']>;
  collection_gt?: InputMaybe<Scalars['Bytes']>;
  collection_lt?: InputMaybe<Scalars['Bytes']>;
  collection_gte?: InputMaybe<Scalars['Bytes']>;
  collection_lte?: InputMaybe<Scalars['Bytes']>;
  collection_in?: InputMaybe<Array<Scalars['Bytes']>>;
  collection_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  collection_contains?: InputMaybe<Scalars['Bytes']>;
  collection_not_contains?: InputMaybe<Scalars['Bytes']>;
  tokenId?: InputMaybe<Scalars['BigInt']>;
  tokenId_not?: InputMaybe<Scalars['BigInt']>;
  tokenId_gt?: InputMaybe<Scalars['BigInt']>;
  tokenId_lt?: InputMaybe<Scalars['BigInt']>;
  tokenId_gte?: InputMaybe<Scalars['BigInt']>;
  tokenId_lte?: InputMaybe<Scalars['BigInt']>;
  tokenId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  assetId?: InputMaybe<Scalars['Bytes']>;
  assetId_not?: InputMaybe<Scalars['Bytes']>;
  assetId_gt?: InputMaybe<Scalars['Bytes']>;
  assetId_lt?: InputMaybe<Scalars['Bytes']>;
  assetId_gte?: InputMaybe<Scalars['Bytes']>;
  assetId_lte?: InputMaybe<Scalars['Bytes']>;
  assetId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_contains?: InputMaybe<Scalars['Bytes']>;
  assetId_not_contains?: InputMaybe<Scalars['Bytes']>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<AddCollateral_filter>>>;
  or?: InputMaybe<Array<InputMaybe<AddCollateral_filter>>>;
};

export type AddCollateral_orderBy =
  | 'id'
  | 'loanId'
  | 'collection'
  | 'tokenId'
  | 'assetId'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash'
  | 'transactionInput';

export type Asset = {
  id: Scalars['ID'];
  collection: Scalars['Bytes'];
  tokenId: Scalars['BigInt'];
  isOnAuction: Scalars['Boolean'];
  orderId: Scalars['Bytes'];
  loan: Loan;
};

export type Asset_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  collection?: InputMaybe<Scalars['Bytes']>;
  collection_not?: InputMaybe<Scalars['Bytes']>;
  collection_gt?: InputMaybe<Scalars['Bytes']>;
  collection_lt?: InputMaybe<Scalars['Bytes']>;
  collection_gte?: InputMaybe<Scalars['Bytes']>;
  collection_lte?: InputMaybe<Scalars['Bytes']>;
  collection_in?: InputMaybe<Array<Scalars['Bytes']>>;
  collection_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  collection_contains?: InputMaybe<Scalars['Bytes']>;
  collection_not_contains?: InputMaybe<Scalars['Bytes']>;
  tokenId?: InputMaybe<Scalars['BigInt']>;
  tokenId_not?: InputMaybe<Scalars['BigInt']>;
  tokenId_gt?: InputMaybe<Scalars['BigInt']>;
  tokenId_lt?: InputMaybe<Scalars['BigInt']>;
  tokenId_gte?: InputMaybe<Scalars['BigInt']>;
  tokenId_lte?: InputMaybe<Scalars['BigInt']>;
  tokenId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  isOnAuction?: InputMaybe<Scalars['Boolean']>;
  isOnAuction_not?: InputMaybe<Scalars['Boolean']>;
  isOnAuction_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isOnAuction_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  orderId?: InputMaybe<Scalars['Bytes']>;
  orderId_not?: InputMaybe<Scalars['Bytes']>;
  orderId_gt?: InputMaybe<Scalars['Bytes']>;
  orderId_lt?: InputMaybe<Scalars['Bytes']>;
  orderId_gte?: InputMaybe<Scalars['Bytes']>;
  orderId_lte?: InputMaybe<Scalars['Bytes']>;
  orderId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_contains?: InputMaybe<Scalars['Bytes']>;
  orderId_not_contains?: InputMaybe<Scalars['Bytes']>;
  loan?: InputMaybe<Scalars['String']>;
  loan_not?: InputMaybe<Scalars['String']>;
  loan_gt?: InputMaybe<Scalars['String']>;
  loan_lt?: InputMaybe<Scalars['String']>;
  loan_gte?: InputMaybe<Scalars['String']>;
  loan_lte?: InputMaybe<Scalars['String']>;
  loan_in?: InputMaybe<Array<Scalars['String']>>;
  loan_not_in?: InputMaybe<Array<Scalars['String']>>;
  loan_contains?: InputMaybe<Scalars['String']>;
  loan_contains_nocase?: InputMaybe<Scalars['String']>;
  loan_not_contains?: InputMaybe<Scalars['String']>;
  loan_not_contains_nocase?: InputMaybe<Scalars['String']>;
  loan_starts_with?: InputMaybe<Scalars['String']>;
  loan_starts_with_nocase?: InputMaybe<Scalars['String']>;
  loan_not_starts_with?: InputMaybe<Scalars['String']>;
  loan_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  loan_ends_with?: InputMaybe<Scalars['String']>;
  loan_ends_with_nocase?: InputMaybe<Scalars['String']>;
  loan_not_ends_with?: InputMaybe<Scalars['String']>;
  loan_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  loan_?: InputMaybe<Loan_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Asset_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Asset_filter>>>;
};

export type Asset_orderBy =
  | 'id'
  | 'collection'
  | 'tokenId'
  | 'isOnAuction'
  | 'orderId'
  | 'loan'
  | 'loan__id'
  | 'loan__status'
  | 'loan__isFrozen'
  | 'loan__amount'
  | 'loan__totalAssets'
  | 'loan__underlyingAsset'
  | 'loan__startDate'
  | 'loan__endDate';

export type AuctionBid = {
  id: Scalars['ID'];
  loanId: Scalars['Bytes'];
  orderId: Scalars['Bytes'];
  assetId: Scalars['Bytes'];
  amountToPay: Scalars['BigInt'];
  amountOfDebt: Scalars['BigInt'];
  user: Scalars['Bytes'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionInput: Scalars['Bytes'];
};

export type AuctionBid_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  orderId?: InputMaybe<Scalars['Bytes']>;
  orderId_not?: InputMaybe<Scalars['Bytes']>;
  orderId_gt?: InputMaybe<Scalars['Bytes']>;
  orderId_lt?: InputMaybe<Scalars['Bytes']>;
  orderId_gte?: InputMaybe<Scalars['Bytes']>;
  orderId_lte?: InputMaybe<Scalars['Bytes']>;
  orderId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_contains?: InputMaybe<Scalars['Bytes']>;
  orderId_not_contains?: InputMaybe<Scalars['Bytes']>;
  assetId?: InputMaybe<Scalars['Bytes']>;
  assetId_not?: InputMaybe<Scalars['Bytes']>;
  assetId_gt?: InputMaybe<Scalars['Bytes']>;
  assetId_lt?: InputMaybe<Scalars['Bytes']>;
  assetId_gte?: InputMaybe<Scalars['Bytes']>;
  assetId_lte?: InputMaybe<Scalars['Bytes']>;
  assetId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_contains?: InputMaybe<Scalars['Bytes']>;
  assetId_not_contains?: InputMaybe<Scalars['Bytes']>;
  amountToPay?: InputMaybe<Scalars['BigInt']>;
  amountToPay_not?: InputMaybe<Scalars['BigInt']>;
  amountToPay_gt?: InputMaybe<Scalars['BigInt']>;
  amountToPay_lt?: InputMaybe<Scalars['BigInt']>;
  amountToPay_gte?: InputMaybe<Scalars['BigInt']>;
  amountToPay_lte?: InputMaybe<Scalars['BigInt']>;
  amountToPay_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountToPay_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountOfDebt?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_not?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_gt?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_lt?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_gte?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_lte?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountOfDebt_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<AuctionBid_filter>>>;
  or?: InputMaybe<Array<InputMaybe<AuctionBid_filter>>>;
};

export type AuctionBid_orderBy =
  | 'id'
  | 'loanId'
  | 'orderId'
  | 'assetId'
  | 'amountToPay'
  | 'amountOfDebt'
  | 'user'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash'
  | 'transactionInput';

export type AuctionFinalize = {
  id: Scalars['ID'];
  loanId: Scalars['Bytes'];
  orderId: Scalars['Bytes'];
  assetId: Scalars['Bytes'];
  debtAmount: Scalars['BigInt'];
  amount: Scalars['BigInt'];
  winner: Scalars['Bytes'];
  owner: Scalars['Bytes'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionInput: Scalars['Bytes'];
};

export type AuctionFinalize_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  orderId?: InputMaybe<Scalars['Bytes']>;
  orderId_not?: InputMaybe<Scalars['Bytes']>;
  orderId_gt?: InputMaybe<Scalars['Bytes']>;
  orderId_lt?: InputMaybe<Scalars['Bytes']>;
  orderId_gte?: InputMaybe<Scalars['Bytes']>;
  orderId_lte?: InputMaybe<Scalars['Bytes']>;
  orderId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_contains?: InputMaybe<Scalars['Bytes']>;
  orderId_not_contains?: InputMaybe<Scalars['Bytes']>;
  assetId?: InputMaybe<Scalars['Bytes']>;
  assetId_not?: InputMaybe<Scalars['Bytes']>;
  assetId_gt?: InputMaybe<Scalars['Bytes']>;
  assetId_lt?: InputMaybe<Scalars['Bytes']>;
  assetId_gte?: InputMaybe<Scalars['Bytes']>;
  assetId_lte?: InputMaybe<Scalars['Bytes']>;
  assetId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_contains?: InputMaybe<Scalars['Bytes']>;
  assetId_not_contains?: InputMaybe<Scalars['Bytes']>;
  debtAmount?: InputMaybe<Scalars['BigInt']>;
  debtAmount_not?: InputMaybe<Scalars['BigInt']>;
  debtAmount_gt?: InputMaybe<Scalars['BigInt']>;
  debtAmount_lt?: InputMaybe<Scalars['BigInt']>;
  debtAmount_gte?: InputMaybe<Scalars['BigInt']>;
  debtAmount_lte?: InputMaybe<Scalars['BigInt']>;
  debtAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  debtAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  winner?: InputMaybe<Scalars['Bytes']>;
  winner_not?: InputMaybe<Scalars['Bytes']>;
  winner_gt?: InputMaybe<Scalars['Bytes']>;
  winner_lt?: InputMaybe<Scalars['Bytes']>;
  winner_gte?: InputMaybe<Scalars['Bytes']>;
  winner_lte?: InputMaybe<Scalars['Bytes']>;
  winner_in?: InputMaybe<Array<Scalars['Bytes']>>;
  winner_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  winner_contains?: InputMaybe<Scalars['Bytes']>;
  winner_not_contains?: InputMaybe<Scalars['Bytes']>;
  owner?: InputMaybe<Scalars['Bytes']>;
  owner_not?: InputMaybe<Scalars['Bytes']>;
  owner_gt?: InputMaybe<Scalars['Bytes']>;
  owner_lt?: InputMaybe<Scalars['Bytes']>;
  owner_gte?: InputMaybe<Scalars['Bytes']>;
  owner_lte?: InputMaybe<Scalars['Bytes']>;
  owner_in?: InputMaybe<Array<Scalars['Bytes']>>;
  owner_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  owner_contains?: InputMaybe<Scalars['Bytes']>;
  owner_not_contains?: InputMaybe<Scalars['Bytes']>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<AuctionFinalize_filter>>>;
  or?: InputMaybe<Array<InputMaybe<AuctionFinalize_filter>>>;
};

export type AuctionFinalize_orderBy =
  | 'id'
  | 'loanId'
  | 'orderId'
  | 'assetId'
  | 'debtAmount'
  | 'amount'
  | 'winner'
  | 'owner'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash'
  | 'transactionInput';

export type AuctionOrderRedeemed = {
  id: Scalars['ID'];
  loanId: Scalars['Bytes'];
  orderId: Scalars['Bytes'];
  amountOfDebt: Scalars['BigInt'];
  amountToPay: Scalars['BigInt'];
  bonus: Scalars['BigInt'];
  countBids: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionInput: Scalars['Bytes'];
};

export type AuctionOrderRedeemed_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  orderId?: InputMaybe<Scalars['Bytes']>;
  orderId_not?: InputMaybe<Scalars['Bytes']>;
  orderId_gt?: InputMaybe<Scalars['Bytes']>;
  orderId_lt?: InputMaybe<Scalars['Bytes']>;
  orderId_gte?: InputMaybe<Scalars['Bytes']>;
  orderId_lte?: InputMaybe<Scalars['Bytes']>;
  orderId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_contains?: InputMaybe<Scalars['Bytes']>;
  orderId_not_contains?: InputMaybe<Scalars['Bytes']>;
  amountOfDebt?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_not?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_gt?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_lt?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_gte?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_lte?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountOfDebt_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountToPay?: InputMaybe<Scalars['BigInt']>;
  amountToPay_not?: InputMaybe<Scalars['BigInt']>;
  amountToPay_gt?: InputMaybe<Scalars['BigInt']>;
  amountToPay_lt?: InputMaybe<Scalars['BigInt']>;
  amountToPay_gte?: InputMaybe<Scalars['BigInt']>;
  amountToPay_lte?: InputMaybe<Scalars['BigInt']>;
  amountToPay_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountToPay_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  bonus?: InputMaybe<Scalars['BigInt']>;
  bonus_not?: InputMaybe<Scalars['BigInt']>;
  bonus_gt?: InputMaybe<Scalars['BigInt']>;
  bonus_lt?: InputMaybe<Scalars['BigInt']>;
  bonus_gte?: InputMaybe<Scalars['BigInt']>;
  bonus_lte?: InputMaybe<Scalars['BigInt']>;
  bonus_in?: InputMaybe<Array<Scalars['BigInt']>>;
  bonus_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  countBids?: InputMaybe<Scalars['BigInt']>;
  countBids_not?: InputMaybe<Scalars['BigInt']>;
  countBids_gt?: InputMaybe<Scalars['BigInt']>;
  countBids_lt?: InputMaybe<Scalars['BigInt']>;
  countBids_gte?: InputMaybe<Scalars['BigInt']>;
  countBids_lte?: InputMaybe<Scalars['BigInt']>;
  countBids_in?: InputMaybe<Array<Scalars['BigInt']>>;
  countBids_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<AuctionOrderRedeemed_filter>>>;
  or?: InputMaybe<Array<InputMaybe<AuctionOrderRedeemed_filter>>>;
};

export type AuctionOrderRedeemed_orderBy =
  | 'id'
  | 'loanId'
  | 'orderId'
  | 'amountOfDebt'
  | 'amountToPay'
  | 'bonus'
  | 'countBids'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash'
  | 'transactionInput';

export type AuctionRedeem = {
  id: Scalars['ID'];
  loanId: Scalars['Bytes'];
  amount: Scalars['BigInt'];
  user: Scalars['Bytes'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionInput: Scalars['Bytes'];
};

export type AuctionRedeem_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<AuctionRedeem_filter>>>;
  or?: InputMaybe<Array<InputMaybe<AuctionRedeem_filter>>>;
};

export type AuctionRedeem_orderBy =
  | 'id'
  | 'loanId'
  | 'amount'
  | 'user'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash'
  | 'transactionInput';

export type Bid = {
  id: Scalars['ID'];
  bidder: Scalars['Bytes'];
  bidAmount: Scalars['BigInt'];
  amountOfDebt: Scalars['BigInt'];
  amountToPay: Scalars['BigInt'];
  order: Order;
};

export type Bid_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  bidder?: InputMaybe<Scalars['Bytes']>;
  bidder_not?: InputMaybe<Scalars['Bytes']>;
  bidder_gt?: InputMaybe<Scalars['Bytes']>;
  bidder_lt?: InputMaybe<Scalars['Bytes']>;
  bidder_gte?: InputMaybe<Scalars['Bytes']>;
  bidder_lte?: InputMaybe<Scalars['Bytes']>;
  bidder_in?: InputMaybe<Array<Scalars['Bytes']>>;
  bidder_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  bidder_contains?: InputMaybe<Scalars['Bytes']>;
  bidder_not_contains?: InputMaybe<Scalars['Bytes']>;
  bidAmount?: InputMaybe<Scalars['BigInt']>;
  bidAmount_not?: InputMaybe<Scalars['BigInt']>;
  bidAmount_gt?: InputMaybe<Scalars['BigInt']>;
  bidAmount_lt?: InputMaybe<Scalars['BigInt']>;
  bidAmount_gte?: InputMaybe<Scalars['BigInt']>;
  bidAmount_lte?: InputMaybe<Scalars['BigInt']>;
  bidAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  bidAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountOfDebt?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_not?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_gt?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_lt?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_gte?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_lte?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountOfDebt_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountToPay?: InputMaybe<Scalars['BigInt']>;
  amountToPay_not?: InputMaybe<Scalars['BigInt']>;
  amountToPay_gt?: InputMaybe<Scalars['BigInt']>;
  amountToPay_lt?: InputMaybe<Scalars['BigInt']>;
  amountToPay_gte?: InputMaybe<Scalars['BigInt']>;
  amountToPay_lte?: InputMaybe<Scalars['BigInt']>;
  amountToPay_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountToPay_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  order?: InputMaybe<Scalars['String']>;
  order_not?: InputMaybe<Scalars['String']>;
  order_gt?: InputMaybe<Scalars['String']>;
  order_lt?: InputMaybe<Scalars['String']>;
  order_gte?: InputMaybe<Scalars['String']>;
  order_lte?: InputMaybe<Scalars['String']>;
  order_in?: InputMaybe<Array<Scalars['String']>>;
  order_not_in?: InputMaybe<Array<Scalars['String']>>;
  order_contains?: InputMaybe<Scalars['String']>;
  order_contains_nocase?: InputMaybe<Scalars['String']>;
  order_not_contains?: InputMaybe<Scalars['String']>;
  order_not_contains_nocase?: InputMaybe<Scalars['String']>;
  order_starts_with?: InputMaybe<Scalars['String']>;
  order_starts_with_nocase?: InputMaybe<Scalars['String']>;
  order_not_starts_with?: InputMaybe<Scalars['String']>;
  order_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  order_ends_with?: InputMaybe<Scalars['String']>;
  order_ends_with_nocase?: InputMaybe<Scalars['String']>;
  order_not_ends_with?: InputMaybe<Scalars['String']>;
  order_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  order_?: InputMaybe<Order_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Bid_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Bid_filter>>>;
};

export type Bid_orderBy =
  | 'id'
  | 'bidder'
  | 'bidAmount'
  | 'amountOfDebt'
  | 'amountToPay'
  | 'order'
  | 'order__id'
  | 'order__status'
  | 'order__orderType'
  | 'order__date'
  | 'order__assetId'
  | 'order__collection'
  | 'order__tokenId'
  | 'order__seller'
  | 'order__loanId'
  | 'order__loanStatus'
  | 'order__debtToSell'
  | 'order__startAmount'
  | 'order__endAmount'
  | 'order__startTime'
  | 'order__endTime'
  | 'order__lastBidder'
  | 'order__lastBidAmount'
  | 'order__bidder'
  | 'order__bidAmount'
  | 'order__buyer'
  | 'order__buyerAmount'
  | 'order__transactionHash';

export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_height = {
  hash?: InputMaybe<Scalars['Bytes']>;
  number?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
};

export type Borrow = {
  id: Scalars['ID'];
  user: Scalars['Bytes'];
  loanId: Scalars['Bytes'];
  amount: Scalars['BigInt'];
  totalAssets: Scalars['BigInt'];
  token: Scalars['Bytes'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionInput: Scalars['Bytes'];
};

export type Borrow_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalAssets?: InputMaybe<Scalars['BigInt']>;
  totalAssets_not?: InputMaybe<Scalars['BigInt']>;
  totalAssets_gt?: InputMaybe<Scalars['BigInt']>;
  totalAssets_lt?: InputMaybe<Scalars['BigInt']>;
  totalAssets_gte?: InputMaybe<Scalars['BigInt']>;
  totalAssets_lte?: InputMaybe<Scalars['BigInt']>;
  totalAssets_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalAssets_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  token?: InputMaybe<Scalars['Bytes']>;
  token_not?: InputMaybe<Scalars['Bytes']>;
  token_gt?: InputMaybe<Scalars['Bytes']>;
  token_lt?: InputMaybe<Scalars['Bytes']>;
  token_gte?: InputMaybe<Scalars['Bytes']>;
  token_lte?: InputMaybe<Scalars['Bytes']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  token_contains?: InputMaybe<Scalars['Bytes']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Borrow_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Borrow_filter>>>;
};

export type Borrow_orderBy =
  | 'id'
  | 'user'
  | 'loanId'
  | 'amount'
  | 'totalAssets'
  | 'token'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash'
  | 'transactionInput';

export type Buyer = {
  id: Scalars['ID'];
  loanId: Scalars['Bytes'];
  assetId: Scalars['Bytes'];
  orderId: Scalars['Bytes'];
  user: Scalars['Bytes'];
};

export type Buyer_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  assetId?: InputMaybe<Scalars['Bytes']>;
  assetId_not?: InputMaybe<Scalars['Bytes']>;
  assetId_gt?: InputMaybe<Scalars['Bytes']>;
  assetId_lt?: InputMaybe<Scalars['Bytes']>;
  assetId_gte?: InputMaybe<Scalars['Bytes']>;
  assetId_lte?: InputMaybe<Scalars['Bytes']>;
  assetId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_contains?: InputMaybe<Scalars['Bytes']>;
  assetId_not_contains?: InputMaybe<Scalars['Bytes']>;
  orderId?: InputMaybe<Scalars['Bytes']>;
  orderId_not?: InputMaybe<Scalars['Bytes']>;
  orderId_gt?: InputMaybe<Scalars['Bytes']>;
  orderId_lt?: InputMaybe<Scalars['Bytes']>;
  orderId_gte?: InputMaybe<Scalars['Bytes']>;
  orderId_lte?: InputMaybe<Scalars['Bytes']>;
  orderId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_contains?: InputMaybe<Scalars['Bytes']>;
  orderId_not_contains?: InputMaybe<Scalars['Bytes']>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Buyer_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Buyer_filter>>>;
};

export type Buyer_orderBy =
  | 'id'
  | 'loanId'
  | 'assetId'
  | 'orderId'
  | 'user';

export type Collection = {
  id: Scalars['ID'];
  isAllowed: Scalars['Boolean'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
};

export type Collection_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  isAllowed?: InputMaybe<Scalars['Boolean']>;
  isAllowed_not?: InputMaybe<Scalars['Boolean']>;
  isAllowed_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isAllowed_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Collection_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Collection_filter>>>;
};

export type Collection_orderBy =
  | 'id'
  | 'isAllowed'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash';

export type ForceSold = {
  id: Scalars['ID'];
  loanId: Scalars['Bytes'];
  assetId: Scalars['Bytes'];
  collection: Scalars['Bytes'];
  tokenId: Scalars['BigInt'];
  marketPrice: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionInput: Scalars['Bytes'];
};

export type ForceSold_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  assetId?: InputMaybe<Scalars['Bytes']>;
  assetId_not?: InputMaybe<Scalars['Bytes']>;
  assetId_gt?: InputMaybe<Scalars['Bytes']>;
  assetId_lt?: InputMaybe<Scalars['Bytes']>;
  assetId_gte?: InputMaybe<Scalars['Bytes']>;
  assetId_lte?: InputMaybe<Scalars['Bytes']>;
  assetId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_contains?: InputMaybe<Scalars['Bytes']>;
  assetId_not_contains?: InputMaybe<Scalars['Bytes']>;
  collection?: InputMaybe<Scalars['Bytes']>;
  collection_not?: InputMaybe<Scalars['Bytes']>;
  collection_gt?: InputMaybe<Scalars['Bytes']>;
  collection_lt?: InputMaybe<Scalars['Bytes']>;
  collection_gte?: InputMaybe<Scalars['Bytes']>;
  collection_lte?: InputMaybe<Scalars['Bytes']>;
  collection_in?: InputMaybe<Array<Scalars['Bytes']>>;
  collection_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  collection_contains?: InputMaybe<Scalars['Bytes']>;
  collection_not_contains?: InputMaybe<Scalars['Bytes']>;
  tokenId?: InputMaybe<Scalars['BigInt']>;
  tokenId_not?: InputMaybe<Scalars['BigInt']>;
  tokenId_gt?: InputMaybe<Scalars['BigInt']>;
  tokenId_lt?: InputMaybe<Scalars['BigInt']>;
  tokenId_gte?: InputMaybe<Scalars['BigInt']>;
  tokenId_lte?: InputMaybe<Scalars['BigInt']>;
  tokenId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  marketPrice?: InputMaybe<Scalars['BigInt']>;
  marketPrice_not?: InputMaybe<Scalars['BigInt']>;
  marketPrice_gt?: InputMaybe<Scalars['BigInt']>;
  marketPrice_lt?: InputMaybe<Scalars['BigInt']>;
  marketPrice_gte?: InputMaybe<Scalars['BigInt']>;
  marketPrice_lte?: InputMaybe<Scalars['BigInt']>;
  marketPrice_in?: InputMaybe<Array<Scalars['BigInt']>>;
  marketPrice_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ForceSold_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ForceSold_filter>>>;
};

export type ForceSold_orderBy =
  | 'id'
  | 'loanId'
  | 'assetId'
  | 'collection'
  | 'tokenId'
  | 'marketPrice'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash'
  | 'transactionInput';

export type FrozenVault = {
  id: Scalars['ID'];
  isFrozen: Scalars['Boolean'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
};

export type FrozenVault_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  isFrozen?: InputMaybe<Scalars['Boolean']>;
  isFrozen_not?: InputMaybe<Scalars['Boolean']>;
  isFrozen_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isFrozen_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<FrozenVault_filter>>>;
  or?: InputMaybe<Array<InputMaybe<FrozenVault_filter>>>;
};

export type FrozenVault_orderBy =
  | 'id'
  | 'isFrozen'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash';

export type Loan = {
  id: Scalars['ID'];
  status: Scalars['BigInt'];
  isFrozen: Scalars['Boolean'];
  user: Account;
  amount: Scalars['BigInt'];
  totalAssets: Scalars['BigInt'];
  underlyingAsset: Scalars['Bytes'];
  assets: Array<Asset>;
  startDate: Scalars['BigInt'];
  endDate: Scalars['BigInt'];
};


export type LoanassetsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Asset_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Asset_filter>;
};

export type LoanCreated = {
  id: Scalars['ID'];
  user: Scalars['Bytes'];
  loanId: Scalars['Bytes'];
  totalAssets: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionInput: Scalars['Bytes'];
};

export type LoanCreated_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  totalAssets?: InputMaybe<Scalars['BigInt']>;
  totalAssets_not?: InputMaybe<Scalars['BigInt']>;
  totalAssets_gt?: InputMaybe<Scalars['BigInt']>;
  totalAssets_lt?: InputMaybe<Scalars['BigInt']>;
  totalAssets_gte?: InputMaybe<Scalars['BigInt']>;
  totalAssets_lte?: InputMaybe<Scalars['BigInt']>;
  totalAssets_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalAssets_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<LoanCreated_filter>>>;
  or?: InputMaybe<Array<InputMaybe<LoanCreated_filter>>>;
};

export type LoanCreated_orderBy =
  | 'id'
  | 'user'
  | 'loanId'
  | 'totalAssets'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash'
  | 'transactionInput';

export type Loan_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<Scalars['BigInt']>;
  status_not?: InputMaybe<Scalars['BigInt']>;
  status_gt?: InputMaybe<Scalars['BigInt']>;
  status_lt?: InputMaybe<Scalars['BigInt']>;
  status_gte?: InputMaybe<Scalars['BigInt']>;
  status_lte?: InputMaybe<Scalars['BigInt']>;
  status_in?: InputMaybe<Array<Scalars['BigInt']>>;
  status_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  isFrozen?: InputMaybe<Scalars['Boolean']>;
  isFrozen_not?: InputMaybe<Scalars['Boolean']>;
  isFrozen_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isFrozen_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  user?: InputMaybe<Scalars['String']>;
  user_not?: InputMaybe<Scalars['String']>;
  user_gt?: InputMaybe<Scalars['String']>;
  user_lt?: InputMaybe<Scalars['String']>;
  user_gte?: InputMaybe<Scalars['String']>;
  user_lte?: InputMaybe<Scalars['String']>;
  user_in?: InputMaybe<Array<Scalars['String']>>;
  user_not_in?: InputMaybe<Array<Scalars['String']>>;
  user_contains?: InputMaybe<Scalars['String']>;
  user_contains_nocase?: InputMaybe<Scalars['String']>;
  user_not_contains?: InputMaybe<Scalars['String']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']>;
  user_starts_with?: InputMaybe<Scalars['String']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user_not_starts_with?: InputMaybe<Scalars['String']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user_ends_with?: InputMaybe<Scalars['String']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_not_ends_with?: InputMaybe<Scalars['String']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_?: InputMaybe<Account_filter>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalAssets?: InputMaybe<Scalars['BigInt']>;
  totalAssets_not?: InputMaybe<Scalars['BigInt']>;
  totalAssets_gt?: InputMaybe<Scalars['BigInt']>;
  totalAssets_lt?: InputMaybe<Scalars['BigInt']>;
  totalAssets_gte?: InputMaybe<Scalars['BigInt']>;
  totalAssets_lte?: InputMaybe<Scalars['BigInt']>;
  totalAssets_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalAssets_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  underlyingAsset?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_not?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_gt?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_lt?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_gte?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_lte?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_in?: InputMaybe<Array<Scalars['Bytes']>>;
  underlyingAsset_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  underlyingAsset_contains?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_not_contains?: InputMaybe<Scalars['Bytes']>;
  assets_?: InputMaybe<Asset_filter>;
  startDate?: InputMaybe<Scalars['BigInt']>;
  startDate_not?: InputMaybe<Scalars['BigInt']>;
  startDate_gt?: InputMaybe<Scalars['BigInt']>;
  startDate_lt?: InputMaybe<Scalars['BigInt']>;
  startDate_gte?: InputMaybe<Scalars['BigInt']>;
  startDate_lte?: InputMaybe<Scalars['BigInt']>;
  startDate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startDate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endDate?: InputMaybe<Scalars['BigInt']>;
  endDate_not?: InputMaybe<Scalars['BigInt']>;
  endDate_gt?: InputMaybe<Scalars['BigInt']>;
  endDate_lt?: InputMaybe<Scalars['BigInt']>;
  endDate_gte?: InputMaybe<Scalars['BigInt']>;
  endDate_lte?: InputMaybe<Scalars['BigInt']>;
  endDate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endDate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Loan_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Loan_filter>>>;
};

export type Loan_orderBy =
  | 'id'
  | 'status'
  | 'isFrozen'
  | 'user'
  | 'user__id'
  | 'user__user'
  | 'user__amountDeposited'
  | 'user__amountBorrowed'
  | 'user__totalAssets'
  | 'amount'
  | 'totalAssets'
  | 'underlyingAsset'
  | 'assets'
  | 'startDate'
  | 'endDate';

export type MarketBid = {
  id: Scalars['ID'];
  loanId: Scalars['Bytes'];
  orderId: Scalars['Bytes'];
  assetId: Scalars['Bytes'];
  amountToPay: Scalars['BigInt'];
  amountOfDebt: Scalars['BigInt'];
  amount: Scalars['BigInt'];
  user: Scalars['Bytes'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionInput: Scalars['Bytes'];
};

export type MarketBid_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  orderId?: InputMaybe<Scalars['Bytes']>;
  orderId_not?: InputMaybe<Scalars['Bytes']>;
  orderId_gt?: InputMaybe<Scalars['Bytes']>;
  orderId_lt?: InputMaybe<Scalars['Bytes']>;
  orderId_gte?: InputMaybe<Scalars['Bytes']>;
  orderId_lte?: InputMaybe<Scalars['Bytes']>;
  orderId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_contains?: InputMaybe<Scalars['Bytes']>;
  orderId_not_contains?: InputMaybe<Scalars['Bytes']>;
  assetId?: InputMaybe<Scalars['Bytes']>;
  assetId_not?: InputMaybe<Scalars['Bytes']>;
  assetId_gt?: InputMaybe<Scalars['Bytes']>;
  assetId_lt?: InputMaybe<Scalars['Bytes']>;
  assetId_gte?: InputMaybe<Scalars['Bytes']>;
  assetId_lte?: InputMaybe<Scalars['Bytes']>;
  assetId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_contains?: InputMaybe<Scalars['Bytes']>;
  assetId_not_contains?: InputMaybe<Scalars['Bytes']>;
  amountToPay?: InputMaybe<Scalars['BigInt']>;
  amountToPay_not?: InputMaybe<Scalars['BigInt']>;
  amountToPay_gt?: InputMaybe<Scalars['BigInt']>;
  amountToPay_lt?: InputMaybe<Scalars['BigInt']>;
  amountToPay_gte?: InputMaybe<Scalars['BigInt']>;
  amountToPay_lte?: InputMaybe<Scalars['BigInt']>;
  amountToPay_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountToPay_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountOfDebt?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_not?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_gt?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_lt?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_gte?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_lte?: InputMaybe<Scalars['BigInt']>;
  amountOfDebt_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amountOfDebt_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MarketBid_filter>>>;
  or?: InputMaybe<Array<InputMaybe<MarketBid_filter>>>;
};

export type MarketBid_orderBy =
  | 'id'
  | 'loanId'
  | 'orderId'
  | 'assetId'
  | 'amountToPay'
  | 'amountOfDebt'
  | 'amount'
  | 'user'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash'
  | 'transactionInput';

export type MarketBuyNow = {
  id: Scalars['ID'];
  loanId: Scalars['Bytes'];
  assetId: Scalars['Bytes'];
  orderId: Scalars['Bytes'];
  amount: Scalars['BigInt'];
  user: Scalars['Bytes'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionInput: Scalars['Bytes'];
};

export type MarketBuyNow_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  assetId?: InputMaybe<Scalars['Bytes']>;
  assetId_not?: InputMaybe<Scalars['Bytes']>;
  assetId_gt?: InputMaybe<Scalars['Bytes']>;
  assetId_lt?: InputMaybe<Scalars['Bytes']>;
  assetId_gte?: InputMaybe<Scalars['Bytes']>;
  assetId_lte?: InputMaybe<Scalars['Bytes']>;
  assetId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_contains?: InputMaybe<Scalars['Bytes']>;
  assetId_not_contains?: InputMaybe<Scalars['Bytes']>;
  orderId?: InputMaybe<Scalars['Bytes']>;
  orderId_not?: InputMaybe<Scalars['Bytes']>;
  orderId_gt?: InputMaybe<Scalars['Bytes']>;
  orderId_lt?: InputMaybe<Scalars['Bytes']>;
  orderId_gte?: InputMaybe<Scalars['Bytes']>;
  orderId_lte?: InputMaybe<Scalars['Bytes']>;
  orderId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_contains?: InputMaybe<Scalars['Bytes']>;
  orderId_not_contains?: InputMaybe<Scalars['Bytes']>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MarketBuyNow_filter>>>;
  or?: InputMaybe<Array<InputMaybe<MarketBuyNow_filter>>>;
};

export type MarketBuyNow_orderBy =
  | 'id'
  | 'loanId'
  | 'assetId'
  | 'orderId'
  | 'amount'
  | 'user'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash'
  | 'transactionInput';

export type MarketClaim = {
  id: Scalars['ID'];
  loanId: Scalars['Bytes'];
  assetId: Scalars['Bytes'];
  orderId: Scalars['Bytes'];
  amount: Scalars['BigInt'];
  bidder: Scalars['Bytes'];
  receiver: Scalars['Bytes'];
  user: Scalars['Bytes'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionInput: Scalars['Bytes'];
};

export type MarketClaim_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  assetId?: InputMaybe<Scalars['Bytes']>;
  assetId_not?: InputMaybe<Scalars['Bytes']>;
  assetId_gt?: InputMaybe<Scalars['Bytes']>;
  assetId_lt?: InputMaybe<Scalars['Bytes']>;
  assetId_gte?: InputMaybe<Scalars['Bytes']>;
  assetId_lte?: InputMaybe<Scalars['Bytes']>;
  assetId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_contains?: InputMaybe<Scalars['Bytes']>;
  assetId_not_contains?: InputMaybe<Scalars['Bytes']>;
  orderId?: InputMaybe<Scalars['Bytes']>;
  orderId_not?: InputMaybe<Scalars['Bytes']>;
  orderId_gt?: InputMaybe<Scalars['Bytes']>;
  orderId_lt?: InputMaybe<Scalars['Bytes']>;
  orderId_gte?: InputMaybe<Scalars['Bytes']>;
  orderId_lte?: InputMaybe<Scalars['Bytes']>;
  orderId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_contains?: InputMaybe<Scalars['Bytes']>;
  orderId_not_contains?: InputMaybe<Scalars['Bytes']>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  bidder?: InputMaybe<Scalars['Bytes']>;
  bidder_not?: InputMaybe<Scalars['Bytes']>;
  bidder_gt?: InputMaybe<Scalars['Bytes']>;
  bidder_lt?: InputMaybe<Scalars['Bytes']>;
  bidder_gte?: InputMaybe<Scalars['Bytes']>;
  bidder_lte?: InputMaybe<Scalars['Bytes']>;
  bidder_in?: InputMaybe<Array<Scalars['Bytes']>>;
  bidder_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  bidder_contains?: InputMaybe<Scalars['Bytes']>;
  bidder_not_contains?: InputMaybe<Scalars['Bytes']>;
  receiver?: InputMaybe<Scalars['Bytes']>;
  receiver_not?: InputMaybe<Scalars['Bytes']>;
  receiver_gt?: InputMaybe<Scalars['Bytes']>;
  receiver_lt?: InputMaybe<Scalars['Bytes']>;
  receiver_gte?: InputMaybe<Scalars['Bytes']>;
  receiver_lte?: InputMaybe<Scalars['Bytes']>;
  receiver_in?: InputMaybe<Array<Scalars['Bytes']>>;
  receiver_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  receiver_contains?: InputMaybe<Scalars['Bytes']>;
  receiver_not_contains?: InputMaybe<Scalars['Bytes']>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MarketClaim_filter>>>;
  or?: InputMaybe<Array<InputMaybe<MarketClaim_filter>>>;
};

export type MarketClaim_orderBy =
  | 'id'
  | 'loanId'
  | 'assetId'
  | 'orderId'
  | 'amount'
  | 'bidder'
  | 'receiver'
  | 'user'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash'
  | 'transactionInput';

export type MarketCreated = {
  id: Scalars['ID'];
  loanId: Scalars['Bytes'];
  assetId: Scalars['Bytes'];
  orderId: Scalars['Bytes'];
  collection: Scalars['Bytes'];
  tokenId: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionInput: Scalars['Bytes'];
};

export type MarketCreated_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  assetId?: InputMaybe<Scalars['Bytes']>;
  assetId_not?: InputMaybe<Scalars['Bytes']>;
  assetId_gt?: InputMaybe<Scalars['Bytes']>;
  assetId_lt?: InputMaybe<Scalars['Bytes']>;
  assetId_gte?: InputMaybe<Scalars['Bytes']>;
  assetId_lte?: InputMaybe<Scalars['Bytes']>;
  assetId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_contains?: InputMaybe<Scalars['Bytes']>;
  assetId_not_contains?: InputMaybe<Scalars['Bytes']>;
  orderId?: InputMaybe<Scalars['Bytes']>;
  orderId_not?: InputMaybe<Scalars['Bytes']>;
  orderId_gt?: InputMaybe<Scalars['Bytes']>;
  orderId_lt?: InputMaybe<Scalars['Bytes']>;
  orderId_gte?: InputMaybe<Scalars['Bytes']>;
  orderId_lte?: InputMaybe<Scalars['Bytes']>;
  orderId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_contains?: InputMaybe<Scalars['Bytes']>;
  orderId_not_contains?: InputMaybe<Scalars['Bytes']>;
  collection?: InputMaybe<Scalars['Bytes']>;
  collection_not?: InputMaybe<Scalars['Bytes']>;
  collection_gt?: InputMaybe<Scalars['Bytes']>;
  collection_lt?: InputMaybe<Scalars['Bytes']>;
  collection_gte?: InputMaybe<Scalars['Bytes']>;
  collection_lte?: InputMaybe<Scalars['Bytes']>;
  collection_in?: InputMaybe<Array<Scalars['Bytes']>>;
  collection_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  collection_contains?: InputMaybe<Scalars['Bytes']>;
  collection_not_contains?: InputMaybe<Scalars['Bytes']>;
  tokenId?: InputMaybe<Scalars['BigInt']>;
  tokenId_not?: InputMaybe<Scalars['BigInt']>;
  tokenId_gt?: InputMaybe<Scalars['BigInt']>;
  tokenId_lt?: InputMaybe<Scalars['BigInt']>;
  tokenId_gte?: InputMaybe<Scalars['BigInt']>;
  tokenId_lte?: InputMaybe<Scalars['BigInt']>;
  tokenId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MarketCreated_filter>>>;
  or?: InputMaybe<Array<InputMaybe<MarketCreated_filter>>>;
};

export type MarketCreated_orderBy =
  | 'id'
  | 'loanId'
  | 'assetId'
  | 'orderId'
  | 'collection'
  | 'tokenId'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash'
  | 'transactionInput';

export type Order = {
  id: Scalars['ID'];
  status: Scalars['BigInt'];
  orderType: Scalars['String'];
  date: Scalars['BigInt'];
  assetId: Scalars['Bytes'];
  collection: Scalars['Bytes'];
  tokenId: Scalars['BigInt'];
  seller: Scalars['Bytes'];
  loanId: Scalars['Bytes'];
  loanStatus: Scalars['BigInt'];
  debtToSell: Scalars['BigInt'];
  startAmount: Scalars['BigInt'];
  endAmount: Scalars['BigInt'];
  startTime: Scalars['BigInt'];
  endTime: Scalars['BigInt'];
  lastBidder: Scalars['Bytes'];
  lastBidAmount: Scalars['BigInt'];
  bidder: Scalars['Bytes'];
  bidAmount: Scalars['BigInt'];
  buyer: Scalars['Bytes'];
  buyerAmount: Scalars['BigInt'];
  bids: Array<Bid>;
  loan: Loan;
  transactionHash: Scalars['Bytes'];
};


export type OrderbidsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Bid_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Bid_filter>;
};

export type OrderCreated = {
  id: Scalars['ID'];
  loanId: Scalars['Bytes'];
  owner: Scalars['Bytes'];
  orderId: Scalars['Bytes'];
  orderType: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionInput: Scalars['Bytes'];
};

export type OrderCreated_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  owner?: InputMaybe<Scalars['Bytes']>;
  owner_not?: InputMaybe<Scalars['Bytes']>;
  owner_gt?: InputMaybe<Scalars['Bytes']>;
  owner_lt?: InputMaybe<Scalars['Bytes']>;
  owner_gte?: InputMaybe<Scalars['Bytes']>;
  owner_lte?: InputMaybe<Scalars['Bytes']>;
  owner_in?: InputMaybe<Array<Scalars['Bytes']>>;
  owner_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  owner_contains?: InputMaybe<Scalars['Bytes']>;
  owner_not_contains?: InputMaybe<Scalars['Bytes']>;
  orderId?: InputMaybe<Scalars['Bytes']>;
  orderId_not?: InputMaybe<Scalars['Bytes']>;
  orderId_gt?: InputMaybe<Scalars['Bytes']>;
  orderId_lt?: InputMaybe<Scalars['Bytes']>;
  orderId_gte?: InputMaybe<Scalars['Bytes']>;
  orderId_lte?: InputMaybe<Scalars['Bytes']>;
  orderId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  orderId_contains?: InputMaybe<Scalars['Bytes']>;
  orderId_not_contains?: InputMaybe<Scalars['Bytes']>;
  orderType?: InputMaybe<Scalars['BigInt']>;
  orderType_not?: InputMaybe<Scalars['BigInt']>;
  orderType_gt?: InputMaybe<Scalars['BigInt']>;
  orderType_lt?: InputMaybe<Scalars['BigInt']>;
  orderType_gte?: InputMaybe<Scalars['BigInt']>;
  orderType_lte?: InputMaybe<Scalars['BigInt']>;
  orderType_in?: InputMaybe<Array<Scalars['BigInt']>>;
  orderType_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<OrderCreated_filter>>>;
  or?: InputMaybe<Array<InputMaybe<OrderCreated_filter>>>;
};

export type OrderCreated_orderBy =
  | 'id'
  | 'loanId'
  | 'owner'
  | 'orderId'
  | 'orderType'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash'
  | 'transactionInput';

/** Defines the order direction, either ascending or descending */
export type OrderDirection =
  | 'asc'
  | 'desc';

export type Order_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<Scalars['BigInt']>;
  status_not?: InputMaybe<Scalars['BigInt']>;
  status_gt?: InputMaybe<Scalars['BigInt']>;
  status_lt?: InputMaybe<Scalars['BigInt']>;
  status_gte?: InputMaybe<Scalars['BigInt']>;
  status_lte?: InputMaybe<Scalars['BigInt']>;
  status_in?: InputMaybe<Array<Scalars['BigInt']>>;
  status_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  orderType?: InputMaybe<Scalars['String']>;
  orderType_not?: InputMaybe<Scalars['String']>;
  orderType_gt?: InputMaybe<Scalars['String']>;
  orderType_lt?: InputMaybe<Scalars['String']>;
  orderType_gte?: InputMaybe<Scalars['String']>;
  orderType_lte?: InputMaybe<Scalars['String']>;
  orderType_in?: InputMaybe<Array<Scalars['String']>>;
  orderType_not_in?: InputMaybe<Array<Scalars['String']>>;
  orderType_contains?: InputMaybe<Scalars['String']>;
  orderType_contains_nocase?: InputMaybe<Scalars['String']>;
  orderType_not_contains?: InputMaybe<Scalars['String']>;
  orderType_not_contains_nocase?: InputMaybe<Scalars['String']>;
  orderType_starts_with?: InputMaybe<Scalars['String']>;
  orderType_starts_with_nocase?: InputMaybe<Scalars['String']>;
  orderType_not_starts_with?: InputMaybe<Scalars['String']>;
  orderType_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  orderType_ends_with?: InputMaybe<Scalars['String']>;
  orderType_ends_with_nocase?: InputMaybe<Scalars['String']>;
  orderType_not_ends_with?: InputMaybe<Scalars['String']>;
  orderType_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['BigInt']>;
  date_not?: InputMaybe<Scalars['BigInt']>;
  date_gt?: InputMaybe<Scalars['BigInt']>;
  date_lt?: InputMaybe<Scalars['BigInt']>;
  date_gte?: InputMaybe<Scalars['BigInt']>;
  date_lte?: InputMaybe<Scalars['BigInt']>;
  date_in?: InputMaybe<Array<Scalars['BigInt']>>;
  date_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  assetId?: InputMaybe<Scalars['Bytes']>;
  assetId_not?: InputMaybe<Scalars['Bytes']>;
  assetId_gt?: InputMaybe<Scalars['Bytes']>;
  assetId_lt?: InputMaybe<Scalars['Bytes']>;
  assetId_gte?: InputMaybe<Scalars['Bytes']>;
  assetId_lte?: InputMaybe<Scalars['Bytes']>;
  assetId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_contains?: InputMaybe<Scalars['Bytes']>;
  assetId_not_contains?: InputMaybe<Scalars['Bytes']>;
  collection?: InputMaybe<Scalars['Bytes']>;
  collection_not?: InputMaybe<Scalars['Bytes']>;
  collection_gt?: InputMaybe<Scalars['Bytes']>;
  collection_lt?: InputMaybe<Scalars['Bytes']>;
  collection_gte?: InputMaybe<Scalars['Bytes']>;
  collection_lte?: InputMaybe<Scalars['Bytes']>;
  collection_in?: InputMaybe<Array<Scalars['Bytes']>>;
  collection_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  collection_contains?: InputMaybe<Scalars['Bytes']>;
  collection_not_contains?: InputMaybe<Scalars['Bytes']>;
  tokenId?: InputMaybe<Scalars['BigInt']>;
  tokenId_not?: InputMaybe<Scalars['BigInt']>;
  tokenId_gt?: InputMaybe<Scalars['BigInt']>;
  tokenId_lt?: InputMaybe<Scalars['BigInt']>;
  tokenId_gte?: InputMaybe<Scalars['BigInt']>;
  tokenId_lte?: InputMaybe<Scalars['BigInt']>;
  tokenId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  seller?: InputMaybe<Scalars['Bytes']>;
  seller_not?: InputMaybe<Scalars['Bytes']>;
  seller_gt?: InputMaybe<Scalars['Bytes']>;
  seller_lt?: InputMaybe<Scalars['Bytes']>;
  seller_gte?: InputMaybe<Scalars['Bytes']>;
  seller_lte?: InputMaybe<Scalars['Bytes']>;
  seller_in?: InputMaybe<Array<Scalars['Bytes']>>;
  seller_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  seller_contains?: InputMaybe<Scalars['Bytes']>;
  seller_not_contains?: InputMaybe<Scalars['Bytes']>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  loanStatus?: InputMaybe<Scalars['BigInt']>;
  loanStatus_not?: InputMaybe<Scalars['BigInt']>;
  loanStatus_gt?: InputMaybe<Scalars['BigInt']>;
  loanStatus_lt?: InputMaybe<Scalars['BigInt']>;
  loanStatus_gte?: InputMaybe<Scalars['BigInt']>;
  loanStatus_lte?: InputMaybe<Scalars['BigInt']>;
  loanStatus_in?: InputMaybe<Array<Scalars['BigInt']>>;
  loanStatus_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  debtToSell?: InputMaybe<Scalars['BigInt']>;
  debtToSell_not?: InputMaybe<Scalars['BigInt']>;
  debtToSell_gt?: InputMaybe<Scalars['BigInt']>;
  debtToSell_lt?: InputMaybe<Scalars['BigInt']>;
  debtToSell_gte?: InputMaybe<Scalars['BigInt']>;
  debtToSell_lte?: InputMaybe<Scalars['BigInt']>;
  debtToSell_in?: InputMaybe<Array<Scalars['BigInt']>>;
  debtToSell_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startAmount?: InputMaybe<Scalars['BigInt']>;
  startAmount_not?: InputMaybe<Scalars['BigInt']>;
  startAmount_gt?: InputMaybe<Scalars['BigInt']>;
  startAmount_lt?: InputMaybe<Scalars['BigInt']>;
  startAmount_gte?: InputMaybe<Scalars['BigInt']>;
  startAmount_lte?: InputMaybe<Scalars['BigInt']>;
  startAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endAmount?: InputMaybe<Scalars['BigInt']>;
  endAmount_not?: InputMaybe<Scalars['BigInt']>;
  endAmount_gt?: InputMaybe<Scalars['BigInt']>;
  endAmount_lt?: InputMaybe<Scalars['BigInt']>;
  endAmount_gte?: InputMaybe<Scalars['BigInt']>;
  endAmount_lte?: InputMaybe<Scalars['BigInt']>;
  endAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startTime?: InputMaybe<Scalars['BigInt']>;
  startTime_not?: InputMaybe<Scalars['BigInt']>;
  startTime_gt?: InputMaybe<Scalars['BigInt']>;
  startTime_lt?: InputMaybe<Scalars['BigInt']>;
  startTime_gte?: InputMaybe<Scalars['BigInt']>;
  startTime_lte?: InputMaybe<Scalars['BigInt']>;
  startTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endTime?: InputMaybe<Scalars['BigInt']>;
  endTime_not?: InputMaybe<Scalars['BigInt']>;
  endTime_gt?: InputMaybe<Scalars['BigInt']>;
  endTime_lt?: InputMaybe<Scalars['BigInt']>;
  endTime_gte?: InputMaybe<Scalars['BigInt']>;
  endTime_lte?: InputMaybe<Scalars['BigInt']>;
  endTime_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endTime_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lastBidder?: InputMaybe<Scalars['Bytes']>;
  lastBidder_not?: InputMaybe<Scalars['Bytes']>;
  lastBidder_gt?: InputMaybe<Scalars['Bytes']>;
  lastBidder_lt?: InputMaybe<Scalars['Bytes']>;
  lastBidder_gte?: InputMaybe<Scalars['Bytes']>;
  lastBidder_lte?: InputMaybe<Scalars['Bytes']>;
  lastBidder_in?: InputMaybe<Array<Scalars['Bytes']>>;
  lastBidder_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  lastBidder_contains?: InputMaybe<Scalars['Bytes']>;
  lastBidder_not_contains?: InputMaybe<Scalars['Bytes']>;
  lastBidAmount?: InputMaybe<Scalars['BigInt']>;
  lastBidAmount_not?: InputMaybe<Scalars['BigInt']>;
  lastBidAmount_gt?: InputMaybe<Scalars['BigInt']>;
  lastBidAmount_lt?: InputMaybe<Scalars['BigInt']>;
  lastBidAmount_gte?: InputMaybe<Scalars['BigInt']>;
  lastBidAmount_lte?: InputMaybe<Scalars['BigInt']>;
  lastBidAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  lastBidAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  bidder?: InputMaybe<Scalars['Bytes']>;
  bidder_not?: InputMaybe<Scalars['Bytes']>;
  bidder_gt?: InputMaybe<Scalars['Bytes']>;
  bidder_lt?: InputMaybe<Scalars['Bytes']>;
  bidder_gte?: InputMaybe<Scalars['Bytes']>;
  bidder_lte?: InputMaybe<Scalars['Bytes']>;
  bidder_in?: InputMaybe<Array<Scalars['Bytes']>>;
  bidder_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  bidder_contains?: InputMaybe<Scalars['Bytes']>;
  bidder_not_contains?: InputMaybe<Scalars['Bytes']>;
  bidAmount?: InputMaybe<Scalars['BigInt']>;
  bidAmount_not?: InputMaybe<Scalars['BigInt']>;
  bidAmount_gt?: InputMaybe<Scalars['BigInt']>;
  bidAmount_lt?: InputMaybe<Scalars['BigInt']>;
  bidAmount_gte?: InputMaybe<Scalars['BigInt']>;
  bidAmount_lte?: InputMaybe<Scalars['BigInt']>;
  bidAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  bidAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  buyer?: InputMaybe<Scalars['Bytes']>;
  buyer_not?: InputMaybe<Scalars['Bytes']>;
  buyer_gt?: InputMaybe<Scalars['Bytes']>;
  buyer_lt?: InputMaybe<Scalars['Bytes']>;
  buyer_gte?: InputMaybe<Scalars['Bytes']>;
  buyer_lte?: InputMaybe<Scalars['Bytes']>;
  buyer_in?: InputMaybe<Array<Scalars['Bytes']>>;
  buyer_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  buyer_contains?: InputMaybe<Scalars['Bytes']>;
  buyer_not_contains?: InputMaybe<Scalars['Bytes']>;
  buyerAmount?: InputMaybe<Scalars['BigInt']>;
  buyerAmount_not?: InputMaybe<Scalars['BigInt']>;
  buyerAmount_gt?: InputMaybe<Scalars['BigInt']>;
  buyerAmount_lt?: InputMaybe<Scalars['BigInt']>;
  buyerAmount_gte?: InputMaybe<Scalars['BigInt']>;
  buyerAmount_lte?: InputMaybe<Scalars['BigInt']>;
  buyerAmount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  buyerAmount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  bids_?: InputMaybe<Bid_filter>;
  loan?: InputMaybe<Scalars['String']>;
  loan_not?: InputMaybe<Scalars['String']>;
  loan_gt?: InputMaybe<Scalars['String']>;
  loan_lt?: InputMaybe<Scalars['String']>;
  loan_gte?: InputMaybe<Scalars['String']>;
  loan_lte?: InputMaybe<Scalars['String']>;
  loan_in?: InputMaybe<Array<Scalars['String']>>;
  loan_not_in?: InputMaybe<Array<Scalars['String']>>;
  loan_contains?: InputMaybe<Scalars['String']>;
  loan_contains_nocase?: InputMaybe<Scalars['String']>;
  loan_not_contains?: InputMaybe<Scalars['String']>;
  loan_not_contains_nocase?: InputMaybe<Scalars['String']>;
  loan_starts_with?: InputMaybe<Scalars['String']>;
  loan_starts_with_nocase?: InputMaybe<Scalars['String']>;
  loan_not_starts_with?: InputMaybe<Scalars['String']>;
  loan_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  loan_ends_with?: InputMaybe<Scalars['String']>;
  loan_ends_with_nocase?: InputMaybe<Scalars['String']>;
  loan_not_ends_with?: InputMaybe<Scalars['String']>;
  loan_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  loan_?: InputMaybe<Loan_filter>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Order_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Order_filter>>>;
};

export type Order_orderBy =
  | 'id'
  | 'status'
  | 'orderType'
  | 'date'
  | 'assetId'
  | 'collection'
  | 'tokenId'
  | 'seller'
  | 'loanId'
  | 'loanStatus'
  | 'debtToSell'
  | 'startAmount'
  | 'endAmount'
  | 'startTime'
  | 'endTime'
  | 'lastBidder'
  | 'lastBidAmount'
  | 'bidder'
  | 'bidAmount'
  | 'buyer'
  | 'buyerAmount'
  | 'bids'
  | 'loan'
  | 'loan__id'
  | 'loan__status'
  | 'loan__isFrozen'
  | 'loan__amount'
  | 'loan__totalAssets'
  | 'loan__underlyingAsset'
  | 'loan__startDate'
  | 'loan__endDate'
  | 'transactionHash';

export type PausedVault = {
  id: Scalars['ID'];
  isPaused: Scalars['Boolean'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
};

export type PausedVault_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  isPaused?: InputMaybe<Scalars['Boolean']>;
  isPaused_not?: InputMaybe<Scalars['Boolean']>;
  isPaused_in?: InputMaybe<Array<Scalars['Boolean']>>;
  isPaused_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PausedVault_filter>>>;
  or?: InputMaybe<Array<InputMaybe<PausedVault_filter>>>;
};

export type PausedVault_orderBy =
  | 'id'
  | 'isPaused'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash';

export type ProxyCreated = {
  id: Scalars['ID'];
  proxy: Scalars['Bytes'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionInput: Scalars['Bytes'];
};

export type ProxyCreated_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  proxy?: InputMaybe<Scalars['Bytes']>;
  proxy_not?: InputMaybe<Scalars['Bytes']>;
  proxy_gt?: InputMaybe<Scalars['Bytes']>;
  proxy_lt?: InputMaybe<Scalars['Bytes']>;
  proxy_gte?: InputMaybe<Scalars['Bytes']>;
  proxy_lte?: InputMaybe<Scalars['Bytes']>;
  proxy_in?: InputMaybe<Array<Scalars['Bytes']>>;
  proxy_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  proxy_contains?: InputMaybe<Scalars['Bytes']>;
  proxy_not_contains?: InputMaybe<Scalars['Bytes']>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ProxyCreated_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ProxyCreated_filter>>>;
};

export type ProxyCreated_orderBy =
  | 'id'
  | 'proxy'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash'
  | 'transactionInput';

export type Punk = {
  id: Scalars['ID'];
  owner: Scalars['Bytes'];
};

export type Punk_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  owner?: InputMaybe<Scalars['Bytes']>;
  owner_not?: InputMaybe<Scalars['Bytes']>;
  owner_gt?: InputMaybe<Scalars['Bytes']>;
  owner_lt?: InputMaybe<Scalars['Bytes']>;
  owner_gte?: InputMaybe<Scalars['Bytes']>;
  owner_lte?: InputMaybe<Scalars['Bytes']>;
  owner_in?: InputMaybe<Array<Scalars['Bytes']>>;
  owner_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  owner_contains?: InputMaybe<Scalars['Bytes']>;
  owner_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Punk_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Punk_filter>>>;
};

export type Punk_orderBy =
  | 'id'
  | 'owner';

export type Query = {
  utokenBorrow?: Maybe<UTokenBorrow>;
  utokenBorrows: Array<UTokenBorrow>;
  utokenRepay?: Maybe<UTokenRepay>;
  utokenRepays: Array<UTokenRepay>;
  supply?: Maybe<Supply>;
  supplies: Array<Supply>;
  withdraw?: Maybe<Withdraw>;
  withdraws: Array<Withdraw>;
  frozenVault?: Maybe<FrozenVault>;
  frozenVaults: Array<FrozenVault>;
  activeVault?: Maybe<ActiveVault>;
  activeVaults: Array<ActiveVault>;
  pausedVault?: Maybe<PausedVault>;
  pausedVaults: Array<PausedVault>;
  borrow?: Maybe<Borrow>;
  borrows: Array<Borrow>;
  addCollateral?: Maybe<AddCollateral>;
  addCollaterals: Array<AddCollateral>;
  repay?: Maybe<Repay>;
  repays: Array<Repay>;
  marketCreated?: Maybe<MarketCreated>;
  marketCreateds: Array<MarketCreated>;
  marketBid?: Maybe<MarketBid>;
  marketBids: Array<MarketBid>;
  marketClaim?: Maybe<MarketClaim>;
  marketClaims: Array<MarketClaim>;
  marketBuyNow?: Maybe<MarketBuyNow>;
  marketBuyNows: Array<MarketBuyNow>;
  auctionOrderRedeemed?: Maybe<AuctionOrderRedeemed>;
  auctionOrderRedeemeds: Array<AuctionOrderRedeemed>;
  auctionBid?: Maybe<AuctionBid>;
  auctionBids: Array<AuctionBid>;
  auctionFinalize?: Maybe<AuctionFinalize>;
  auctionFinalizes: Array<AuctionFinalize>;
  auctionRedeem?: Maybe<AuctionRedeem>;
  auctionRedeems: Array<AuctionRedeem>;
  sold?: Maybe<Sold>;
  solds: Array<Sold>;
  forceSold?: Maybe<ForceSold>;
  forceSolds: Array<ForceSold>;
  proxyCreated?: Maybe<ProxyCreated>;
  proxyCreateds: Array<ProxyCreated>;
  orderCreated?: Maybe<OrderCreated>;
  orderCreateds: Array<OrderCreated>;
  loanCreated?: Maybe<LoanCreated>;
  loanCreateds: Array<LoanCreated>;
  punk?: Maybe<Punk>;
  punks: Array<Punk>;
  buyer?: Maybe<Buyer>;
  buyers: Array<Buyer>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  totalCount?: Maybe<TotalCount>;
  totalCounts: Array<TotalCount>;
  collection?: Maybe<Collection>;
  collections: Array<Collection>;
  loan?: Maybe<Loan>;
  loans: Array<Loan>;
  asset?: Maybe<Asset>;
  assets: Array<Asset>;
  order?: Maybe<Order>;
  orders: Array<Order>;
  bid?: Maybe<Bid>;
  bids: Array<Bid>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type QueryutokenBorrowArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryutokenBorrowsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UTokenBorrow_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UTokenBorrow_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryutokenRepayArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryutokenRepaysArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UTokenRepay_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UTokenRepay_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerysupplyArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerysuppliesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Supply_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Supply_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerywithdrawArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerywithdrawsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Withdraw_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Withdraw_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryfrozenVaultArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryfrozenVaultsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FrozenVault_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<FrozenVault_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryactiveVaultArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryactiveVaultsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ActiveVault_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ActiveVault_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerypausedVaultArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerypausedVaultsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PausedVault_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PausedVault_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryborrowArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryborrowsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Borrow_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Borrow_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryaddCollateralArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryaddCollateralsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AddCollateral_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AddCollateral_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryrepayArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryrepaysArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Repay_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Repay_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymarketCreatedArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymarketCreatedsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MarketCreated_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MarketCreated_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymarketBidArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymarketBidsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MarketBid_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MarketBid_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymarketClaimArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymarketClaimsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MarketClaim_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MarketClaim_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymarketBuyNowArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymarketBuyNowsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MarketBuyNow_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MarketBuyNow_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryauctionOrderRedeemedArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryauctionOrderRedeemedsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuctionOrderRedeemed_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AuctionOrderRedeemed_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryauctionBidArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryauctionBidsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuctionBid_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AuctionBid_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryauctionFinalizeArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryauctionFinalizesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuctionFinalize_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AuctionFinalize_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryauctionRedeemArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryauctionRedeemsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuctionRedeem_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AuctionRedeem_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerysoldArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerysoldsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Sold_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Sold_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryforceSoldArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryforceSoldsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ForceSold_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ForceSold_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryproxyCreatedArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryproxyCreatedsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProxyCreated_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ProxyCreated_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryorderCreatedArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryorderCreatedsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderCreated_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<OrderCreated_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryloanCreatedArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryloanCreatedsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LoanCreated_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<LoanCreated_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerypunkArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerypunksArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Punk_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Punk_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerybuyerArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerybuyersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Buyer_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Buyer_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryaccountArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryaccountsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Account_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Account_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytotalCountArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytotalCountsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TotalCount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TotalCount_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycollectionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycollectionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Collection_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Collection_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryloanArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryloansArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Loan_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Loan_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryassetArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryassetsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Asset_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Asset_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryorderArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryordersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Order_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Order_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerybidArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerybidsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Bid_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Bid_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Query_metaArgs = {
  block?: InputMaybe<Block_height>;
};

export type Repay = {
  id: Scalars['ID'];
  user: Scalars['Bytes'];
  loanId: Scalars['Bytes'];
  amount: Scalars['BigInt'];
  assets: Array<Scalars['Bytes']>;
  totalAssets: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionInput: Scalars['Bytes'];
};

export type Repay_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  assets?: InputMaybe<Array<Scalars['Bytes']>>;
  assets_not?: InputMaybe<Array<Scalars['Bytes']>>;
  assets_contains?: InputMaybe<Array<Scalars['Bytes']>>;
  assets_contains_nocase?: InputMaybe<Array<Scalars['Bytes']>>;
  assets_not_contains?: InputMaybe<Array<Scalars['Bytes']>>;
  assets_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']>>;
  totalAssets?: InputMaybe<Scalars['BigInt']>;
  totalAssets_not?: InputMaybe<Scalars['BigInt']>;
  totalAssets_gt?: InputMaybe<Scalars['BigInt']>;
  totalAssets_lt?: InputMaybe<Scalars['BigInt']>;
  totalAssets_gte?: InputMaybe<Scalars['BigInt']>;
  totalAssets_lte?: InputMaybe<Scalars['BigInt']>;
  totalAssets_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalAssets_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Repay_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Repay_filter>>>;
};

export type Repay_orderBy =
  | 'id'
  | 'user'
  | 'loanId'
  | 'amount'
  | 'assets'
  | 'totalAssets'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash'
  | 'transactionInput';

export type Sold = {
  id: Scalars['ID'];
  assetId: Scalars['Bytes'];
  collection: Scalars['Bytes'];
  tokenId: Scalars['BigInt'];
  amount: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
  transactionInput: Scalars['Bytes'];
};

export type Sold_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  assetId?: InputMaybe<Scalars['Bytes']>;
  assetId_not?: InputMaybe<Scalars['Bytes']>;
  assetId_gt?: InputMaybe<Scalars['Bytes']>;
  assetId_lt?: InputMaybe<Scalars['Bytes']>;
  assetId_gte?: InputMaybe<Scalars['Bytes']>;
  assetId_lte?: InputMaybe<Scalars['Bytes']>;
  assetId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  assetId_contains?: InputMaybe<Scalars['Bytes']>;
  assetId_not_contains?: InputMaybe<Scalars['Bytes']>;
  collection?: InputMaybe<Scalars['Bytes']>;
  collection_not?: InputMaybe<Scalars['Bytes']>;
  collection_gt?: InputMaybe<Scalars['Bytes']>;
  collection_lt?: InputMaybe<Scalars['Bytes']>;
  collection_gte?: InputMaybe<Scalars['Bytes']>;
  collection_lte?: InputMaybe<Scalars['Bytes']>;
  collection_in?: InputMaybe<Array<Scalars['Bytes']>>;
  collection_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  collection_contains?: InputMaybe<Scalars['Bytes']>;
  collection_not_contains?: InputMaybe<Scalars['Bytes']>;
  tokenId?: InputMaybe<Scalars['BigInt']>;
  tokenId_not?: InputMaybe<Scalars['BigInt']>;
  tokenId_gt?: InputMaybe<Scalars['BigInt']>;
  tokenId_lt?: InputMaybe<Scalars['BigInt']>;
  tokenId_gte?: InputMaybe<Scalars['BigInt']>;
  tokenId_lte?: InputMaybe<Scalars['BigInt']>;
  tokenId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lt?: InputMaybe<Scalars['Bytes']>;
  transactionInput_gte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_lte?: InputMaybe<Scalars['Bytes']>;
  transactionInput_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionInput_contains?: InputMaybe<Scalars['Bytes']>;
  transactionInput_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Sold_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Sold_filter>>>;
};

export type Sold_orderBy =
  | 'id'
  | 'assetId'
  | 'collection'
  | 'tokenId'
  | 'amount'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash'
  | 'transactionInput';

export type Subscription = {
  utokenBorrow?: Maybe<UTokenBorrow>;
  utokenBorrows: Array<UTokenBorrow>;
  utokenRepay?: Maybe<UTokenRepay>;
  utokenRepays: Array<UTokenRepay>;
  supply?: Maybe<Supply>;
  supplies: Array<Supply>;
  withdraw?: Maybe<Withdraw>;
  withdraws: Array<Withdraw>;
  frozenVault?: Maybe<FrozenVault>;
  frozenVaults: Array<FrozenVault>;
  activeVault?: Maybe<ActiveVault>;
  activeVaults: Array<ActiveVault>;
  pausedVault?: Maybe<PausedVault>;
  pausedVaults: Array<PausedVault>;
  borrow?: Maybe<Borrow>;
  borrows: Array<Borrow>;
  addCollateral?: Maybe<AddCollateral>;
  addCollaterals: Array<AddCollateral>;
  repay?: Maybe<Repay>;
  repays: Array<Repay>;
  marketCreated?: Maybe<MarketCreated>;
  marketCreateds: Array<MarketCreated>;
  marketBid?: Maybe<MarketBid>;
  marketBids: Array<MarketBid>;
  marketClaim?: Maybe<MarketClaim>;
  marketClaims: Array<MarketClaim>;
  marketBuyNow?: Maybe<MarketBuyNow>;
  marketBuyNows: Array<MarketBuyNow>;
  auctionOrderRedeemed?: Maybe<AuctionOrderRedeemed>;
  auctionOrderRedeemeds: Array<AuctionOrderRedeemed>;
  auctionBid?: Maybe<AuctionBid>;
  auctionBids: Array<AuctionBid>;
  auctionFinalize?: Maybe<AuctionFinalize>;
  auctionFinalizes: Array<AuctionFinalize>;
  auctionRedeem?: Maybe<AuctionRedeem>;
  auctionRedeems: Array<AuctionRedeem>;
  sold?: Maybe<Sold>;
  solds: Array<Sold>;
  forceSold?: Maybe<ForceSold>;
  forceSolds: Array<ForceSold>;
  proxyCreated?: Maybe<ProxyCreated>;
  proxyCreateds: Array<ProxyCreated>;
  orderCreated?: Maybe<OrderCreated>;
  orderCreateds: Array<OrderCreated>;
  loanCreated?: Maybe<LoanCreated>;
  loanCreateds: Array<LoanCreated>;
  punk?: Maybe<Punk>;
  punks: Array<Punk>;
  buyer?: Maybe<Buyer>;
  buyers: Array<Buyer>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  totalCount?: Maybe<TotalCount>;
  totalCounts: Array<TotalCount>;
  collection?: Maybe<Collection>;
  collections: Array<Collection>;
  loan?: Maybe<Loan>;
  loans: Array<Loan>;
  asset?: Maybe<Asset>;
  assets: Array<Asset>;
  order?: Maybe<Order>;
  orders: Array<Order>;
  bid?: Maybe<Bid>;
  bids: Array<Bid>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type SubscriptionutokenBorrowArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionutokenBorrowsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UTokenBorrow_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UTokenBorrow_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionutokenRepayArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionutokenRepaysArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UTokenRepay_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UTokenRepay_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionsupplyArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionsuppliesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Supply_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Supply_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionwithdrawArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionwithdrawsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Withdraw_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Withdraw_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionfrozenVaultArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionfrozenVaultsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<FrozenVault_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<FrozenVault_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionactiveVaultArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionactiveVaultsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ActiveVault_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ActiveVault_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionpausedVaultArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionpausedVaultsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<PausedVault_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PausedVault_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionborrowArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionborrowsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Borrow_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Borrow_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionaddCollateralArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionaddCollateralsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AddCollateral_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AddCollateral_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionrepayArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionrepaysArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Repay_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Repay_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmarketCreatedArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmarketCreatedsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MarketCreated_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MarketCreated_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmarketBidArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmarketBidsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MarketBid_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MarketBid_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmarketClaimArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmarketClaimsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MarketClaim_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MarketClaim_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmarketBuyNowArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmarketBuyNowsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MarketBuyNow_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MarketBuyNow_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionauctionOrderRedeemedArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionauctionOrderRedeemedsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuctionOrderRedeemed_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AuctionOrderRedeemed_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionauctionBidArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionauctionBidsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuctionBid_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AuctionBid_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionauctionFinalizeArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionauctionFinalizesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuctionFinalize_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AuctionFinalize_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionauctionRedeemArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionauctionRedeemsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AuctionRedeem_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AuctionRedeem_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionsoldArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionsoldsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Sold_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Sold_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionforceSoldArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionforceSoldsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ForceSold_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ForceSold_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionproxyCreatedArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionproxyCreatedsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ProxyCreated_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ProxyCreated_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionorderCreatedArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionorderCreatedsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderCreated_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<OrderCreated_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionloanCreatedArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionloanCreatedsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LoanCreated_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<LoanCreated_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionpunkArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionpunksArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Punk_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Punk_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionbuyerArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionbuyersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Buyer_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Buyer_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionaccountArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionaccountsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Account_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Account_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontotalCountArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontotalCountsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TotalCount_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TotalCount_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncollectionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncollectionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Collection_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Collection_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionloanArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionloansArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Loan_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Loan_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionassetArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionassetsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Asset_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Asset_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionorderArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionordersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Order_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Order_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionbidArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionbidsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Bid_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Bid_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscription_metaArgs = {
  block?: InputMaybe<Block_height>;
};

export type Supply = {
  id: Scalars['ID'];
  user: Scalars['Bytes'];
  onBehalfOf: Scalars['Bytes'];
  underlyingAsset: Scalars['Bytes'];
  amount: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
};

export type Supply_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_not?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_gt?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_lt?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_gte?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_lte?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_in?: InputMaybe<Array<Scalars['Bytes']>>;
  onBehalfOf_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  onBehalfOf_contains?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_not_contains?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_not?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_gt?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_lt?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_gte?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_lte?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_in?: InputMaybe<Array<Scalars['Bytes']>>;
  underlyingAsset_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  underlyingAsset_contains?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_not_contains?: InputMaybe<Scalars['Bytes']>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Supply_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Supply_filter>>>;
};

export type Supply_orderBy =
  | 'id'
  | 'user'
  | 'onBehalfOf'
  | 'underlyingAsset'
  | 'amount'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash';

export type TotalCount = {
  id: Scalars['ID'];
  totalCount: Scalars['BigInt'];
};

export type TotalCount_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  totalCount?: InputMaybe<Scalars['BigInt']>;
  totalCount_not?: InputMaybe<Scalars['BigInt']>;
  totalCount_gt?: InputMaybe<Scalars['BigInt']>;
  totalCount_lt?: InputMaybe<Scalars['BigInt']>;
  totalCount_gte?: InputMaybe<Scalars['BigInt']>;
  totalCount_lte?: InputMaybe<Scalars['BigInt']>;
  totalCount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalCount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TotalCount_filter>>>;
  or?: InputMaybe<Array<InputMaybe<TotalCount_filter>>>;
};

export type TotalCount_orderBy =
  | 'id'
  | 'totalCount';

export type UTokenBorrow = {
  id: Scalars['ID'];
  onBehalfOf: Scalars['Bytes'];
  iniciator: Scalars['Bytes'];
  amount: Scalars['BigInt'];
  loanId: Scalars['Bytes'];
  underlyingAsset: Scalars['Bytes'];
  borrowRate: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
};

export type UTokenBorrow_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  onBehalfOf?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_not?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_gt?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_lt?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_gte?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_lte?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_in?: InputMaybe<Array<Scalars['Bytes']>>;
  onBehalfOf_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  onBehalfOf_contains?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_not_contains?: InputMaybe<Scalars['Bytes']>;
  iniciator?: InputMaybe<Scalars['Bytes']>;
  iniciator_not?: InputMaybe<Scalars['Bytes']>;
  iniciator_gt?: InputMaybe<Scalars['Bytes']>;
  iniciator_lt?: InputMaybe<Scalars['Bytes']>;
  iniciator_gte?: InputMaybe<Scalars['Bytes']>;
  iniciator_lte?: InputMaybe<Scalars['Bytes']>;
  iniciator_in?: InputMaybe<Array<Scalars['Bytes']>>;
  iniciator_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  iniciator_contains?: InputMaybe<Scalars['Bytes']>;
  iniciator_not_contains?: InputMaybe<Scalars['Bytes']>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_not?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_gt?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_lt?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_gte?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_lte?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_in?: InputMaybe<Array<Scalars['Bytes']>>;
  underlyingAsset_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  underlyingAsset_contains?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_not_contains?: InputMaybe<Scalars['Bytes']>;
  borrowRate?: InputMaybe<Scalars['BigInt']>;
  borrowRate_not?: InputMaybe<Scalars['BigInt']>;
  borrowRate_gt?: InputMaybe<Scalars['BigInt']>;
  borrowRate_lt?: InputMaybe<Scalars['BigInt']>;
  borrowRate_gte?: InputMaybe<Scalars['BigInt']>;
  borrowRate_lte?: InputMaybe<Scalars['BigInt']>;
  borrowRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  borrowRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<UTokenBorrow_filter>>>;
  or?: InputMaybe<Array<InputMaybe<UTokenBorrow_filter>>>;
};

export type UTokenBorrow_orderBy =
  | 'id'
  | 'onBehalfOf'
  | 'iniciator'
  | 'amount'
  | 'loanId'
  | 'underlyingAsset'
  | 'borrowRate'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash';

export type UTokenRepay = {
  id: Scalars['ID'];
  iniciator: Scalars['Bytes'];
  loanId: Scalars['Bytes'];
  underlyingAsset: Scalars['Bytes'];
  amount: Scalars['BigInt'];
  onBehalfOf: Scalars['Bytes'];
  borrowRate: Scalars['BigInt'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
};

export type UTokenRepay_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  iniciator?: InputMaybe<Scalars['Bytes']>;
  iniciator_not?: InputMaybe<Scalars['Bytes']>;
  iniciator_gt?: InputMaybe<Scalars['Bytes']>;
  iniciator_lt?: InputMaybe<Scalars['Bytes']>;
  iniciator_gte?: InputMaybe<Scalars['Bytes']>;
  iniciator_lte?: InputMaybe<Scalars['Bytes']>;
  iniciator_in?: InputMaybe<Array<Scalars['Bytes']>>;
  iniciator_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  iniciator_contains?: InputMaybe<Scalars['Bytes']>;
  iniciator_not_contains?: InputMaybe<Scalars['Bytes']>;
  loanId?: InputMaybe<Scalars['Bytes']>;
  loanId_not?: InputMaybe<Scalars['Bytes']>;
  loanId_gt?: InputMaybe<Scalars['Bytes']>;
  loanId_lt?: InputMaybe<Scalars['Bytes']>;
  loanId_gte?: InputMaybe<Scalars['Bytes']>;
  loanId_lte?: InputMaybe<Scalars['Bytes']>;
  loanId_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  loanId_contains?: InputMaybe<Scalars['Bytes']>;
  loanId_not_contains?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_not?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_gt?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_lt?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_gte?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_lte?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_in?: InputMaybe<Array<Scalars['Bytes']>>;
  underlyingAsset_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  underlyingAsset_contains?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_not_contains?: InputMaybe<Scalars['Bytes']>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  onBehalfOf?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_not?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_gt?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_lt?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_gte?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_lte?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_in?: InputMaybe<Array<Scalars['Bytes']>>;
  onBehalfOf_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  onBehalfOf_contains?: InputMaybe<Scalars['Bytes']>;
  onBehalfOf_not_contains?: InputMaybe<Scalars['Bytes']>;
  borrowRate?: InputMaybe<Scalars['BigInt']>;
  borrowRate_not?: InputMaybe<Scalars['BigInt']>;
  borrowRate_gt?: InputMaybe<Scalars['BigInt']>;
  borrowRate_lt?: InputMaybe<Scalars['BigInt']>;
  borrowRate_gte?: InputMaybe<Scalars['BigInt']>;
  borrowRate_lte?: InputMaybe<Scalars['BigInt']>;
  borrowRate_in?: InputMaybe<Array<Scalars['BigInt']>>;
  borrowRate_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<UTokenRepay_filter>>>;
  or?: InputMaybe<Array<InputMaybe<UTokenRepay_filter>>>;
};

export type UTokenRepay_orderBy =
  | 'id'
  | 'iniciator'
  | 'loanId'
  | 'underlyingAsset'
  | 'amount'
  | 'onBehalfOf'
  | 'borrowRate'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash';

export type Withdraw = {
  id: Scalars['ID'];
  user: Scalars['Bytes'];
  underlyingAsset: Scalars['Bytes'];
  amount: Scalars['BigInt'];
  to: Scalars['Bytes'];
  blockNumber: Scalars['BigInt'];
  blockTimestamp: Scalars['BigInt'];
  transactionHash: Scalars['Bytes'];
};

export type Withdraw_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  user?: InputMaybe<Scalars['Bytes']>;
  user_not?: InputMaybe<Scalars['Bytes']>;
  user_gt?: InputMaybe<Scalars['Bytes']>;
  user_lt?: InputMaybe<Scalars['Bytes']>;
  user_gte?: InputMaybe<Scalars['Bytes']>;
  user_lte?: InputMaybe<Scalars['Bytes']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  user_contains?: InputMaybe<Scalars['Bytes']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_not?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_gt?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_lt?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_gte?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_lte?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_in?: InputMaybe<Array<Scalars['Bytes']>>;
  underlyingAsset_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  underlyingAsset_contains?: InputMaybe<Scalars['Bytes']>;
  underlyingAsset_not_contains?: InputMaybe<Scalars['Bytes']>;
  amount?: InputMaybe<Scalars['BigInt']>;
  amount_not?: InputMaybe<Scalars['BigInt']>;
  amount_gt?: InputMaybe<Scalars['BigInt']>;
  amount_lt?: InputMaybe<Scalars['BigInt']>;
  amount_gte?: InputMaybe<Scalars['BigInt']>;
  amount_lte?: InputMaybe<Scalars['BigInt']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  to?: InputMaybe<Scalars['Bytes']>;
  to_not?: InputMaybe<Scalars['Bytes']>;
  to_gt?: InputMaybe<Scalars['Bytes']>;
  to_lt?: InputMaybe<Scalars['Bytes']>;
  to_gte?: InputMaybe<Scalars['Bytes']>;
  to_lte?: InputMaybe<Scalars['Bytes']>;
  to_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  to_contains?: InputMaybe<Scalars['Bytes']>;
  to_not_contains?: InputMaybe<Scalars['Bytes']>;
  blockNumber?: InputMaybe<Scalars['BigInt']>;
  blockNumber_not?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lt?: InputMaybe<Scalars['BigInt']>;
  blockNumber_gte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_lte?: InputMaybe<Scalars['BigInt']>;
  blockNumber_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockNumber_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_not?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lt?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_gte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_lte?: InputMaybe<Scalars['BigInt']>;
  blockTimestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  blockTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Withdraw_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Withdraw_filter>>>;
};

export type Withdraw_orderBy =
  | 'id'
  | 'user'
  | 'underlyingAsset'
  | 'amount'
  | 'to'
  | 'blockNumber'
  | 'blockTimestamp'
  | 'transactionHash';

export type _Block_ = {
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export type _SubgraphErrorPolicy_ =
  /** Data will be returned even if the subgraph has indexing errors */
  | 'allow'
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  | 'deny';

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string | ((fieldNode: FieldNode) => SelectionSetNode);
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Account: ResolverTypeWrapper<Account>;
  Account_filter: Account_filter;
  Account_orderBy: Account_orderBy;
  ActiveVault: ResolverTypeWrapper<ActiveVault>;
  ActiveVault_filter: ActiveVault_filter;
  ActiveVault_orderBy: ActiveVault_orderBy;
  AddCollateral: ResolverTypeWrapper<AddCollateral>;
  AddCollateral_filter: AddCollateral_filter;
  AddCollateral_orderBy: AddCollateral_orderBy;
  Asset: ResolverTypeWrapper<Asset>;
  Asset_filter: Asset_filter;
  Asset_orderBy: Asset_orderBy;
  AuctionBid: ResolverTypeWrapper<AuctionBid>;
  AuctionBid_filter: AuctionBid_filter;
  AuctionBid_orderBy: AuctionBid_orderBy;
  AuctionFinalize: ResolverTypeWrapper<AuctionFinalize>;
  AuctionFinalize_filter: AuctionFinalize_filter;
  AuctionFinalize_orderBy: AuctionFinalize_orderBy;
  AuctionOrderRedeemed: ResolverTypeWrapper<AuctionOrderRedeemed>;
  AuctionOrderRedeemed_filter: AuctionOrderRedeemed_filter;
  AuctionOrderRedeemed_orderBy: AuctionOrderRedeemed_orderBy;
  AuctionRedeem: ResolverTypeWrapper<AuctionRedeem>;
  AuctionRedeem_filter: AuctionRedeem_filter;
  AuctionRedeem_orderBy: AuctionRedeem_orderBy;
  Bid: ResolverTypeWrapper<Bid>;
  Bid_filter: Bid_filter;
  Bid_orderBy: Bid_orderBy;
  BigDecimal: ResolverTypeWrapper<Scalars['BigDecimal']>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']>;
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Borrow: ResolverTypeWrapper<Borrow>;
  Borrow_filter: Borrow_filter;
  Borrow_orderBy: Borrow_orderBy;
  Buyer: ResolverTypeWrapper<Buyer>;
  Buyer_filter: Buyer_filter;
  Buyer_orderBy: Buyer_orderBy;
  Bytes: ResolverTypeWrapper<Scalars['Bytes']>;
  Collection: ResolverTypeWrapper<Collection>;
  Collection_filter: Collection_filter;
  Collection_orderBy: Collection_orderBy;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  ForceSold: ResolverTypeWrapper<ForceSold>;
  ForceSold_filter: ForceSold_filter;
  ForceSold_orderBy: ForceSold_orderBy;
  FrozenVault: ResolverTypeWrapper<FrozenVault>;
  FrozenVault_filter: FrozenVault_filter;
  FrozenVault_orderBy: FrozenVault_orderBy;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Int8: ResolverTypeWrapper<Scalars['Int8']>;
  Loan: ResolverTypeWrapper<Loan>;
  LoanCreated: ResolverTypeWrapper<LoanCreated>;
  LoanCreated_filter: LoanCreated_filter;
  LoanCreated_orderBy: LoanCreated_orderBy;
  Loan_filter: Loan_filter;
  Loan_orderBy: Loan_orderBy;
  MarketBid: ResolverTypeWrapper<MarketBid>;
  MarketBid_filter: MarketBid_filter;
  MarketBid_orderBy: MarketBid_orderBy;
  MarketBuyNow: ResolverTypeWrapper<MarketBuyNow>;
  MarketBuyNow_filter: MarketBuyNow_filter;
  MarketBuyNow_orderBy: MarketBuyNow_orderBy;
  MarketClaim: ResolverTypeWrapper<MarketClaim>;
  MarketClaim_filter: MarketClaim_filter;
  MarketClaim_orderBy: MarketClaim_orderBy;
  MarketCreated: ResolverTypeWrapper<MarketCreated>;
  MarketCreated_filter: MarketCreated_filter;
  MarketCreated_orderBy: MarketCreated_orderBy;
  Order: ResolverTypeWrapper<Order>;
  OrderCreated: ResolverTypeWrapper<OrderCreated>;
  OrderCreated_filter: OrderCreated_filter;
  OrderCreated_orderBy: OrderCreated_orderBy;
  OrderDirection: OrderDirection;
  Order_filter: Order_filter;
  Order_orderBy: Order_orderBy;
  PausedVault: ResolverTypeWrapper<PausedVault>;
  PausedVault_filter: PausedVault_filter;
  PausedVault_orderBy: PausedVault_orderBy;
  ProxyCreated: ResolverTypeWrapper<ProxyCreated>;
  ProxyCreated_filter: ProxyCreated_filter;
  ProxyCreated_orderBy: ProxyCreated_orderBy;
  Punk: ResolverTypeWrapper<Punk>;
  Punk_filter: Punk_filter;
  Punk_orderBy: Punk_orderBy;
  Query: ResolverTypeWrapper<{}>;
  Repay: ResolverTypeWrapper<Repay>;
  Repay_filter: Repay_filter;
  Repay_orderBy: Repay_orderBy;
  Sold: ResolverTypeWrapper<Sold>;
  Sold_filter: Sold_filter;
  Sold_orderBy: Sold_orderBy;
  String: ResolverTypeWrapper<Scalars['String']>;
  Subscription: ResolverTypeWrapper<{}>;
  Supply: ResolverTypeWrapper<Supply>;
  Supply_filter: Supply_filter;
  Supply_orderBy: Supply_orderBy;
  TotalCount: ResolverTypeWrapper<TotalCount>;
  TotalCount_filter: TotalCount_filter;
  TotalCount_orderBy: TotalCount_orderBy;
  UTokenBorrow: ResolverTypeWrapper<UTokenBorrow>;
  UTokenBorrow_filter: UTokenBorrow_filter;
  UTokenBorrow_orderBy: UTokenBorrow_orderBy;
  UTokenRepay: ResolverTypeWrapper<UTokenRepay>;
  UTokenRepay_filter: UTokenRepay_filter;
  UTokenRepay_orderBy: UTokenRepay_orderBy;
  Withdraw: ResolverTypeWrapper<Withdraw>;
  Withdraw_filter: Withdraw_filter;
  Withdraw_orderBy: Withdraw_orderBy;
  _Block_: ResolverTypeWrapper<_Block_>;
  _Meta_: ResolverTypeWrapper<_Meta_>;
  _SubgraphErrorPolicy_: _SubgraphErrorPolicy_;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Account: Account;
  Account_filter: Account_filter;
  ActiveVault: ActiveVault;
  ActiveVault_filter: ActiveVault_filter;
  AddCollateral: AddCollateral;
  AddCollateral_filter: AddCollateral_filter;
  Asset: Asset;
  Asset_filter: Asset_filter;
  AuctionBid: AuctionBid;
  AuctionBid_filter: AuctionBid_filter;
  AuctionFinalize: AuctionFinalize;
  AuctionFinalize_filter: AuctionFinalize_filter;
  AuctionOrderRedeemed: AuctionOrderRedeemed;
  AuctionOrderRedeemed_filter: AuctionOrderRedeemed_filter;
  AuctionRedeem: AuctionRedeem;
  AuctionRedeem_filter: AuctionRedeem_filter;
  Bid: Bid;
  Bid_filter: Bid_filter;
  BigDecimal: Scalars['BigDecimal'];
  BigInt: Scalars['BigInt'];
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: Scalars['Boolean'];
  Borrow: Borrow;
  Borrow_filter: Borrow_filter;
  Buyer: Buyer;
  Buyer_filter: Buyer_filter;
  Bytes: Scalars['Bytes'];
  Collection: Collection;
  Collection_filter: Collection_filter;
  Float: Scalars['Float'];
  ForceSold: ForceSold;
  ForceSold_filter: ForceSold_filter;
  FrozenVault: FrozenVault;
  FrozenVault_filter: FrozenVault_filter;
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  Int8: Scalars['Int8'];
  Loan: Loan;
  LoanCreated: LoanCreated;
  LoanCreated_filter: LoanCreated_filter;
  Loan_filter: Loan_filter;
  MarketBid: MarketBid;
  MarketBid_filter: MarketBid_filter;
  MarketBuyNow: MarketBuyNow;
  MarketBuyNow_filter: MarketBuyNow_filter;
  MarketClaim: MarketClaim;
  MarketClaim_filter: MarketClaim_filter;
  MarketCreated: MarketCreated;
  MarketCreated_filter: MarketCreated_filter;
  Order: Order;
  OrderCreated: OrderCreated;
  OrderCreated_filter: OrderCreated_filter;
  Order_filter: Order_filter;
  PausedVault: PausedVault;
  PausedVault_filter: PausedVault_filter;
  ProxyCreated: ProxyCreated;
  ProxyCreated_filter: ProxyCreated_filter;
  Punk: Punk;
  Punk_filter: Punk_filter;
  Query: {};
  Repay: Repay;
  Repay_filter: Repay_filter;
  Sold: Sold;
  Sold_filter: Sold_filter;
  String: Scalars['String'];
  Subscription: {};
  Supply: Supply;
  Supply_filter: Supply_filter;
  TotalCount: TotalCount;
  TotalCount_filter: TotalCount_filter;
  UTokenBorrow: UTokenBorrow;
  UTokenBorrow_filter: UTokenBorrow_filter;
  UTokenRepay: UTokenRepay;
  UTokenRepay_filter: UTokenRepay_filter;
  Withdraw: Withdraw;
  Withdraw_filter: Withdraw_filter;
  _Block_: _Block_;
  _Meta_: _Meta_;
}>;

export type entityDirectiveArgs = { };

export type entityDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = entityDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type subgraphIdDirectiveArgs = {
  id: Scalars['String'];
};

export type subgraphIdDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = subgraphIdDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type derivedFromDirectiveArgs = {
  field: Scalars['String'];
};

export type derivedFromDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = derivedFromDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AccountResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Account'] = ResolversParentTypes['Account']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amountDeposited?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amountBorrowed?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAssets?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  borrows?: Resolver<Array<ResolversTypes['Loan']>, ParentType, ContextType, RequireFields<AccountborrowsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ActiveVaultResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ActiveVault'] = ResolversParentTypes['ActiveVault']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddCollateralResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['AddCollateral'] = ResolversParentTypes['AddCollateral']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  collection?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  tokenId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  assetId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionInput?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AssetResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Asset'] = ResolversParentTypes['Asset']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  collection?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  tokenId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  isOnAuction?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  loan?: Resolver<ResolversTypes['Loan'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AuctionBidResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['AuctionBid'] = ResolversParentTypes['AuctionBid']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  assetId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amountToPay?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amountOfDebt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionInput?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AuctionFinalizeResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['AuctionFinalize'] = ResolversParentTypes['AuctionFinalize']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  assetId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  debtAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  winner?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionInput?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AuctionOrderRedeemedResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['AuctionOrderRedeemed'] = ResolversParentTypes['AuctionOrderRedeemed']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amountOfDebt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amountToPay?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  bonus?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  countBids?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionInput?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AuctionRedeemResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['AuctionRedeem'] = ResolversParentTypes['AuctionRedeem']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionInput?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BidResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Bid'] = ResolversParentTypes['Bid']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  bidder?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  bidAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amountOfDebt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amountToPay?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Order'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface BigDecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigDecimal'], any> {
  name: 'BigDecimal';
}

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export type BorrowResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Borrow'] = ResolversParentTypes['Borrow']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAssets?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionInput?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BuyerResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Buyer'] = ResolversParentTypes['Buyer']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  assetId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface BytesScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Bytes'], any> {
  name: 'Bytes';
}

export type CollectionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Collection'] = ResolversParentTypes['Collection']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isAllowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ForceSoldResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ForceSold'] = ResolversParentTypes['ForceSold']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  assetId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  collection?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  tokenId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  marketPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionInput?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FrozenVaultResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['FrozenVault'] = ResolversParentTypes['FrozenVault']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isFrozen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface Int8ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Int8'], any> {
  name: 'Int8';
}

export type LoanResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Loan'] = ResolversParentTypes['Loan']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  isFrozen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalAssets?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  underlyingAsset?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  assets?: Resolver<Array<ResolversTypes['Asset']>, ParentType, ContextType, RequireFields<LoanassetsArgs, 'skip' | 'first'>>;
  startDate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LoanCreatedResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['LoanCreated'] = ResolversParentTypes['LoanCreated']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  totalAssets?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionInput?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketBidResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['MarketBid'] = ResolversParentTypes['MarketBid']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  assetId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amountToPay?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amountOfDebt?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionInput?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketBuyNowResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['MarketBuyNow'] = ResolversParentTypes['MarketBuyNow']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  assetId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionInput?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketClaimResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['MarketClaim'] = ResolversParentTypes['MarketClaim']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  assetId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  bidder?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  receiver?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionInput?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketCreatedResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['MarketCreated'] = ResolversParentTypes['MarketCreated']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  assetId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  collection?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  tokenId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionInput?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Order'] = ResolversParentTypes['Order']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  orderType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  assetId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  collection?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  tokenId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  seller?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  loanStatus?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  debtToSell?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  startAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  endAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  lastBidder?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  lastBidAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  bidder?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  bidAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  buyer?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  buyerAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  bids?: Resolver<Array<ResolversTypes['Bid']>, ParentType, ContextType, RequireFields<OrderbidsArgs, 'skip' | 'first'>>;
  loan?: Resolver<ResolversTypes['Loan'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderCreatedResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['OrderCreated'] = ResolversParentTypes['OrderCreated']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  orderType?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionInput?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PausedVaultResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['PausedVault'] = ResolversParentTypes['PausedVault']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isPaused?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProxyCreatedResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ProxyCreated'] = ResolversParentTypes['ProxyCreated']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  proxy?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionInput?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PunkResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Punk'] = ResolversParentTypes['Punk']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  utokenBorrow?: Resolver<Maybe<ResolversTypes['UTokenBorrow']>, ParentType, ContextType, RequireFields<QueryutokenBorrowArgs, 'id' | 'subgraphError'>>;
  utokenBorrows?: Resolver<Array<ResolversTypes['UTokenBorrow']>, ParentType, ContextType, RequireFields<QueryutokenBorrowsArgs, 'skip' | 'first' | 'subgraphError'>>;
  utokenRepay?: Resolver<Maybe<ResolversTypes['UTokenRepay']>, ParentType, ContextType, RequireFields<QueryutokenRepayArgs, 'id' | 'subgraphError'>>;
  utokenRepays?: Resolver<Array<ResolversTypes['UTokenRepay']>, ParentType, ContextType, RequireFields<QueryutokenRepaysArgs, 'skip' | 'first' | 'subgraphError'>>;
  supply?: Resolver<Maybe<ResolversTypes['Supply']>, ParentType, ContextType, RequireFields<QuerysupplyArgs, 'id' | 'subgraphError'>>;
  supplies?: Resolver<Array<ResolversTypes['Supply']>, ParentType, ContextType, RequireFields<QuerysuppliesArgs, 'skip' | 'first' | 'subgraphError'>>;
  withdraw?: Resolver<Maybe<ResolversTypes['Withdraw']>, ParentType, ContextType, RequireFields<QuerywithdrawArgs, 'id' | 'subgraphError'>>;
  withdraws?: Resolver<Array<ResolversTypes['Withdraw']>, ParentType, ContextType, RequireFields<QuerywithdrawsArgs, 'skip' | 'first' | 'subgraphError'>>;
  frozenVault?: Resolver<Maybe<ResolversTypes['FrozenVault']>, ParentType, ContextType, RequireFields<QueryfrozenVaultArgs, 'id' | 'subgraphError'>>;
  frozenVaults?: Resolver<Array<ResolversTypes['FrozenVault']>, ParentType, ContextType, RequireFields<QueryfrozenVaultsArgs, 'skip' | 'first' | 'subgraphError'>>;
  activeVault?: Resolver<Maybe<ResolversTypes['ActiveVault']>, ParentType, ContextType, RequireFields<QueryactiveVaultArgs, 'id' | 'subgraphError'>>;
  activeVaults?: Resolver<Array<ResolversTypes['ActiveVault']>, ParentType, ContextType, RequireFields<QueryactiveVaultsArgs, 'skip' | 'first' | 'subgraphError'>>;
  pausedVault?: Resolver<Maybe<ResolversTypes['PausedVault']>, ParentType, ContextType, RequireFields<QuerypausedVaultArgs, 'id' | 'subgraphError'>>;
  pausedVaults?: Resolver<Array<ResolversTypes['PausedVault']>, ParentType, ContextType, RequireFields<QuerypausedVaultsArgs, 'skip' | 'first' | 'subgraphError'>>;
  borrow?: Resolver<Maybe<ResolversTypes['Borrow']>, ParentType, ContextType, RequireFields<QueryborrowArgs, 'id' | 'subgraphError'>>;
  borrows?: Resolver<Array<ResolversTypes['Borrow']>, ParentType, ContextType, RequireFields<QueryborrowsArgs, 'skip' | 'first' | 'subgraphError'>>;
  addCollateral?: Resolver<Maybe<ResolversTypes['AddCollateral']>, ParentType, ContextType, RequireFields<QueryaddCollateralArgs, 'id' | 'subgraphError'>>;
  addCollaterals?: Resolver<Array<ResolversTypes['AddCollateral']>, ParentType, ContextType, RequireFields<QueryaddCollateralsArgs, 'skip' | 'first' | 'subgraphError'>>;
  repay?: Resolver<Maybe<ResolversTypes['Repay']>, ParentType, ContextType, RequireFields<QueryrepayArgs, 'id' | 'subgraphError'>>;
  repays?: Resolver<Array<ResolversTypes['Repay']>, ParentType, ContextType, RequireFields<QueryrepaysArgs, 'skip' | 'first' | 'subgraphError'>>;
  marketCreated?: Resolver<Maybe<ResolversTypes['MarketCreated']>, ParentType, ContextType, RequireFields<QuerymarketCreatedArgs, 'id' | 'subgraphError'>>;
  marketCreateds?: Resolver<Array<ResolversTypes['MarketCreated']>, ParentType, ContextType, RequireFields<QuerymarketCreatedsArgs, 'skip' | 'first' | 'subgraphError'>>;
  marketBid?: Resolver<Maybe<ResolversTypes['MarketBid']>, ParentType, ContextType, RequireFields<QuerymarketBidArgs, 'id' | 'subgraphError'>>;
  marketBids?: Resolver<Array<ResolversTypes['MarketBid']>, ParentType, ContextType, RequireFields<QuerymarketBidsArgs, 'skip' | 'first' | 'subgraphError'>>;
  marketClaim?: Resolver<Maybe<ResolversTypes['MarketClaim']>, ParentType, ContextType, RequireFields<QuerymarketClaimArgs, 'id' | 'subgraphError'>>;
  marketClaims?: Resolver<Array<ResolversTypes['MarketClaim']>, ParentType, ContextType, RequireFields<QuerymarketClaimsArgs, 'skip' | 'first' | 'subgraphError'>>;
  marketBuyNow?: Resolver<Maybe<ResolversTypes['MarketBuyNow']>, ParentType, ContextType, RequireFields<QuerymarketBuyNowArgs, 'id' | 'subgraphError'>>;
  marketBuyNows?: Resolver<Array<ResolversTypes['MarketBuyNow']>, ParentType, ContextType, RequireFields<QuerymarketBuyNowsArgs, 'skip' | 'first' | 'subgraphError'>>;
  auctionOrderRedeemed?: Resolver<Maybe<ResolversTypes['AuctionOrderRedeemed']>, ParentType, ContextType, RequireFields<QueryauctionOrderRedeemedArgs, 'id' | 'subgraphError'>>;
  auctionOrderRedeemeds?: Resolver<Array<ResolversTypes['AuctionOrderRedeemed']>, ParentType, ContextType, RequireFields<QueryauctionOrderRedeemedsArgs, 'skip' | 'first' | 'subgraphError'>>;
  auctionBid?: Resolver<Maybe<ResolversTypes['AuctionBid']>, ParentType, ContextType, RequireFields<QueryauctionBidArgs, 'id' | 'subgraphError'>>;
  auctionBids?: Resolver<Array<ResolversTypes['AuctionBid']>, ParentType, ContextType, RequireFields<QueryauctionBidsArgs, 'skip' | 'first' | 'subgraphError'>>;
  auctionFinalize?: Resolver<Maybe<ResolversTypes['AuctionFinalize']>, ParentType, ContextType, RequireFields<QueryauctionFinalizeArgs, 'id' | 'subgraphError'>>;
  auctionFinalizes?: Resolver<Array<ResolversTypes['AuctionFinalize']>, ParentType, ContextType, RequireFields<QueryauctionFinalizesArgs, 'skip' | 'first' | 'subgraphError'>>;
  auctionRedeem?: Resolver<Maybe<ResolversTypes['AuctionRedeem']>, ParentType, ContextType, RequireFields<QueryauctionRedeemArgs, 'id' | 'subgraphError'>>;
  auctionRedeems?: Resolver<Array<ResolversTypes['AuctionRedeem']>, ParentType, ContextType, RequireFields<QueryauctionRedeemsArgs, 'skip' | 'first' | 'subgraphError'>>;
  sold?: Resolver<Maybe<ResolversTypes['Sold']>, ParentType, ContextType, RequireFields<QuerysoldArgs, 'id' | 'subgraphError'>>;
  solds?: Resolver<Array<ResolversTypes['Sold']>, ParentType, ContextType, RequireFields<QuerysoldsArgs, 'skip' | 'first' | 'subgraphError'>>;
  forceSold?: Resolver<Maybe<ResolversTypes['ForceSold']>, ParentType, ContextType, RequireFields<QueryforceSoldArgs, 'id' | 'subgraphError'>>;
  forceSolds?: Resolver<Array<ResolversTypes['ForceSold']>, ParentType, ContextType, RequireFields<QueryforceSoldsArgs, 'skip' | 'first' | 'subgraphError'>>;
  proxyCreated?: Resolver<Maybe<ResolversTypes['ProxyCreated']>, ParentType, ContextType, RequireFields<QueryproxyCreatedArgs, 'id' | 'subgraphError'>>;
  proxyCreateds?: Resolver<Array<ResolversTypes['ProxyCreated']>, ParentType, ContextType, RequireFields<QueryproxyCreatedsArgs, 'skip' | 'first' | 'subgraphError'>>;
  orderCreated?: Resolver<Maybe<ResolversTypes['OrderCreated']>, ParentType, ContextType, RequireFields<QueryorderCreatedArgs, 'id' | 'subgraphError'>>;
  orderCreateds?: Resolver<Array<ResolversTypes['OrderCreated']>, ParentType, ContextType, RequireFields<QueryorderCreatedsArgs, 'skip' | 'first' | 'subgraphError'>>;
  loanCreated?: Resolver<Maybe<ResolversTypes['LoanCreated']>, ParentType, ContextType, RequireFields<QueryloanCreatedArgs, 'id' | 'subgraphError'>>;
  loanCreateds?: Resolver<Array<ResolversTypes['LoanCreated']>, ParentType, ContextType, RequireFields<QueryloanCreatedsArgs, 'skip' | 'first' | 'subgraphError'>>;
  punk?: Resolver<Maybe<ResolversTypes['Punk']>, ParentType, ContextType, RequireFields<QuerypunkArgs, 'id' | 'subgraphError'>>;
  punks?: Resolver<Array<ResolversTypes['Punk']>, ParentType, ContextType, RequireFields<QuerypunksArgs, 'skip' | 'first' | 'subgraphError'>>;
  buyer?: Resolver<Maybe<ResolversTypes['Buyer']>, ParentType, ContextType, RequireFields<QuerybuyerArgs, 'id' | 'subgraphError'>>;
  buyers?: Resolver<Array<ResolversTypes['Buyer']>, ParentType, ContextType, RequireFields<QuerybuyersArgs, 'skip' | 'first' | 'subgraphError'>>;
  account?: Resolver<Maybe<ResolversTypes['Account']>, ParentType, ContextType, RequireFields<QueryaccountArgs, 'id' | 'subgraphError'>>;
  accounts?: Resolver<Array<ResolversTypes['Account']>, ParentType, ContextType, RequireFields<QueryaccountsArgs, 'skip' | 'first' | 'subgraphError'>>;
  totalCount?: Resolver<Maybe<ResolversTypes['TotalCount']>, ParentType, ContextType, RequireFields<QuerytotalCountArgs, 'id' | 'subgraphError'>>;
  totalCounts?: Resolver<Array<ResolversTypes['TotalCount']>, ParentType, ContextType, RequireFields<QuerytotalCountsArgs, 'skip' | 'first' | 'subgraphError'>>;
  collection?: Resolver<Maybe<ResolversTypes['Collection']>, ParentType, ContextType, RequireFields<QuerycollectionArgs, 'id' | 'subgraphError'>>;
  collections?: Resolver<Array<ResolversTypes['Collection']>, ParentType, ContextType, RequireFields<QuerycollectionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  loan?: Resolver<Maybe<ResolversTypes['Loan']>, ParentType, ContextType, RequireFields<QueryloanArgs, 'id' | 'subgraphError'>>;
  loans?: Resolver<Array<ResolversTypes['Loan']>, ParentType, ContextType, RequireFields<QueryloansArgs, 'skip' | 'first' | 'subgraphError'>>;
  asset?: Resolver<Maybe<ResolversTypes['Asset']>, ParentType, ContextType, RequireFields<QueryassetArgs, 'id' | 'subgraphError'>>;
  assets?: Resolver<Array<ResolversTypes['Asset']>, ParentType, ContextType, RequireFields<QueryassetsArgs, 'skip' | 'first' | 'subgraphError'>>;
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType, RequireFields<QueryorderArgs, 'id' | 'subgraphError'>>;
  orders?: Resolver<Array<ResolversTypes['Order']>, ParentType, ContextType, RequireFields<QueryordersArgs, 'skip' | 'first' | 'subgraphError'>>;
  bid?: Resolver<Maybe<ResolversTypes['Bid']>, ParentType, ContextType, RequireFields<QuerybidArgs, 'id' | 'subgraphError'>>;
  bids?: Resolver<Array<ResolversTypes['Bid']>, ParentType, ContextType, RequireFields<QuerybidsArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: Resolver<Maybe<ResolversTypes['_Meta_']>, ParentType, ContextType, Partial<Query_metaArgs>>;
}>;

export type RepayResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Repay'] = ResolversParentTypes['Repay']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  assets?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  totalAssets?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionInput?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SoldResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Sold'] = ResolversParentTypes['Sold']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  assetId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  collection?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  tokenId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionInput?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = ResolversObject<{
  utokenBorrow?: SubscriptionResolver<Maybe<ResolversTypes['UTokenBorrow']>, "utokenBorrow", ParentType, ContextType, RequireFields<SubscriptionutokenBorrowArgs, 'id' | 'subgraphError'>>;
  utokenBorrows?: SubscriptionResolver<Array<ResolversTypes['UTokenBorrow']>, "utokenBorrows", ParentType, ContextType, RequireFields<SubscriptionutokenBorrowsArgs, 'skip' | 'first' | 'subgraphError'>>;
  utokenRepay?: SubscriptionResolver<Maybe<ResolversTypes['UTokenRepay']>, "utokenRepay", ParentType, ContextType, RequireFields<SubscriptionutokenRepayArgs, 'id' | 'subgraphError'>>;
  utokenRepays?: SubscriptionResolver<Array<ResolversTypes['UTokenRepay']>, "utokenRepays", ParentType, ContextType, RequireFields<SubscriptionutokenRepaysArgs, 'skip' | 'first' | 'subgraphError'>>;
  supply?: SubscriptionResolver<Maybe<ResolversTypes['Supply']>, "supply", ParentType, ContextType, RequireFields<SubscriptionsupplyArgs, 'id' | 'subgraphError'>>;
  supplies?: SubscriptionResolver<Array<ResolversTypes['Supply']>, "supplies", ParentType, ContextType, RequireFields<SubscriptionsuppliesArgs, 'skip' | 'first' | 'subgraphError'>>;
  withdraw?: SubscriptionResolver<Maybe<ResolversTypes['Withdraw']>, "withdraw", ParentType, ContextType, RequireFields<SubscriptionwithdrawArgs, 'id' | 'subgraphError'>>;
  withdraws?: SubscriptionResolver<Array<ResolversTypes['Withdraw']>, "withdraws", ParentType, ContextType, RequireFields<SubscriptionwithdrawsArgs, 'skip' | 'first' | 'subgraphError'>>;
  frozenVault?: SubscriptionResolver<Maybe<ResolversTypes['FrozenVault']>, "frozenVault", ParentType, ContextType, RequireFields<SubscriptionfrozenVaultArgs, 'id' | 'subgraphError'>>;
  frozenVaults?: SubscriptionResolver<Array<ResolversTypes['FrozenVault']>, "frozenVaults", ParentType, ContextType, RequireFields<SubscriptionfrozenVaultsArgs, 'skip' | 'first' | 'subgraphError'>>;
  activeVault?: SubscriptionResolver<Maybe<ResolversTypes['ActiveVault']>, "activeVault", ParentType, ContextType, RequireFields<SubscriptionactiveVaultArgs, 'id' | 'subgraphError'>>;
  activeVaults?: SubscriptionResolver<Array<ResolversTypes['ActiveVault']>, "activeVaults", ParentType, ContextType, RequireFields<SubscriptionactiveVaultsArgs, 'skip' | 'first' | 'subgraphError'>>;
  pausedVault?: SubscriptionResolver<Maybe<ResolversTypes['PausedVault']>, "pausedVault", ParentType, ContextType, RequireFields<SubscriptionpausedVaultArgs, 'id' | 'subgraphError'>>;
  pausedVaults?: SubscriptionResolver<Array<ResolversTypes['PausedVault']>, "pausedVaults", ParentType, ContextType, RequireFields<SubscriptionpausedVaultsArgs, 'skip' | 'first' | 'subgraphError'>>;
  borrow?: SubscriptionResolver<Maybe<ResolversTypes['Borrow']>, "borrow", ParentType, ContextType, RequireFields<SubscriptionborrowArgs, 'id' | 'subgraphError'>>;
  borrows?: SubscriptionResolver<Array<ResolversTypes['Borrow']>, "borrows", ParentType, ContextType, RequireFields<SubscriptionborrowsArgs, 'skip' | 'first' | 'subgraphError'>>;
  addCollateral?: SubscriptionResolver<Maybe<ResolversTypes['AddCollateral']>, "addCollateral", ParentType, ContextType, RequireFields<SubscriptionaddCollateralArgs, 'id' | 'subgraphError'>>;
  addCollaterals?: SubscriptionResolver<Array<ResolversTypes['AddCollateral']>, "addCollaterals", ParentType, ContextType, RequireFields<SubscriptionaddCollateralsArgs, 'skip' | 'first' | 'subgraphError'>>;
  repay?: SubscriptionResolver<Maybe<ResolversTypes['Repay']>, "repay", ParentType, ContextType, RequireFields<SubscriptionrepayArgs, 'id' | 'subgraphError'>>;
  repays?: SubscriptionResolver<Array<ResolversTypes['Repay']>, "repays", ParentType, ContextType, RequireFields<SubscriptionrepaysArgs, 'skip' | 'first' | 'subgraphError'>>;
  marketCreated?: SubscriptionResolver<Maybe<ResolversTypes['MarketCreated']>, "marketCreated", ParentType, ContextType, RequireFields<SubscriptionmarketCreatedArgs, 'id' | 'subgraphError'>>;
  marketCreateds?: SubscriptionResolver<Array<ResolversTypes['MarketCreated']>, "marketCreateds", ParentType, ContextType, RequireFields<SubscriptionmarketCreatedsArgs, 'skip' | 'first' | 'subgraphError'>>;
  marketBid?: SubscriptionResolver<Maybe<ResolversTypes['MarketBid']>, "marketBid", ParentType, ContextType, RequireFields<SubscriptionmarketBidArgs, 'id' | 'subgraphError'>>;
  marketBids?: SubscriptionResolver<Array<ResolversTypes['MarketBid']>, "marketBids", ParentType, ContextType, RequireFields<SubscriptionmarketBidsArgs, 'skip' | 'first' | 'subgraphError'>>;
  marketClaim?: SubscriptionResolver<Maybe<ResolversTypes['MarketClaim']>, "marketClaim", ParentType, ContextType, RequireFields<SubscriptionmarketClaimArgs, 'id' | 'subgraphError'>>;
  marketClaims?: SubscriptionResolver<Array<ResolversTypes['MarketClaim']>, "marketClaims", ParentType, ContextType, RequireFields<SubscriptionmarketClaimsArgs, 'skip' | 'first' | 'subgraphError'>>;
  marketBuyNow?: SubscriptionResolver<Maybe<ResolversTypes['MarketBuyNow']>, "marketBuyNow", ParentType, ContextType, RequireFields<SubscriptionmarketBuyNowArgs, 'id' | 'subgraphError'>>;
  marketBuyNows?: SubscriptionResolver<Array<ResolversTypes['MarketBuyNow']>, "marketBuyNows", ParentType, ContextType, RequireFields<SubscriptionmarketBuyNowsArgs, 'skip' | 'first' | 'subgraphError'>>;
  auctionOrderRedeemed?: SubscriptionResolver<Maybe<ResolversTypes['AuctionOrderRedeemed']>, "auctionOrderRedeemed", ParentType, ContextType, RequireFields<SubscriptionauctionOrderRedeemedArgs, 'id' | 'subgraphError'>>;
  auctionOrderRedeemeds?: SubscriptionResolver<Array<ResolversTypes['AuctionOrderRedeemed']>, "auctionOrderRedeemeds", ParentType, ContextType, RequireFields<SubscriptionauctionOrderRedeemedsArgs, 'skip' | 'first' | 'subgraphError'>>;
  auctionBid?: SubscriptionResolver<Maybe<ResolversTypes['AuctionBid']>, "auctionBid", ParentType, ContextType, RequireFields<SubscriptionauctionBidArgs, 'id' | 'subgraphError'>>;
  auctionBids?: SubscriptionResolver<Array<ResolversTypes['AuctionBid']>, "auctionBids", ParentType, ContextType, RequireFields<SubscriptionauctionBidsArgs, 'skip' | 'first' | 'subgraphError'>>;
  auctionFinalize?: SubscriptionResolver<Maybe<ResolversTypes['AuctionFinalize']>, "auctionFinalize", ParentType, ContextType, RequireFields<SubscriptionauctionFinalizeArgs, 'id' | 'subgraphError'>>;
  auctionFinalizes?: SubscriptionResolver<Array<ResolversTypes['AuctionFinalize']>, "auctionFinalizes", ParentType, ContextType, RequireFields<SubscriptionauctionFinalizesArgs, 'skip' | 'first' | 'subgraphError'>>;
  auctionRedeem?: SubscriptionResolver<Maybe<ResolversTypes['AuctionRedeem']>, "auctionRedeem", ParentType, ContextType, RequireFields<SubscriptionauctionRedeemArgs, 'id' | 'subgraphError'>>;
  auctionRedeems?: SubscriptionResolver<Array<ResolversTypes['AuctionRedeem']>, "auctionRedeems", ParentType, ContextType, RequireFields<SubscriptionauctionRedeemsArgs, 'skip' | 'first' | 'subgraphError'>>;
  sold?: SubscriptionResolver<Maybe<ResolversTypes['Sold']>, "sold", ParentType, ContextType, RequireFields<SubscriptionsoldArgs, 'id' | 'subgraphError'>>;
  solds?: SubscriptionResolver<Array<ResolversTypes['Sold']>, "solds", ParentType, ContextType, RequireFields<SubscriptionsoldsArgs, 'skip' | 'first' | 'subgraphError'>>;
  forceSold?: SubscriptionResolver<Maybe<ResolversTypes['ForceSold']>, "forceSold", ParentType, ContextType, RequireFields<SubscriptionforceSoldArgs, 'id' | 'subgraphError'>>;
  forceSolds?: SubscriptionResolver<Array<ResolversTypes['ForceSold']>, "forceSolds", ParentType, ContextType, RequireFields<SubscriptionforceSoldsArgs, 'skip' | 'first' | 'subgraphError'>>;
  proxyCreated?: SubscriptionResolver<Maybe<ResolversTypes['ProxyCreated']>, "proxyCreated", ParentType, ContextType, RequireFields<SubscriptionproxyCreatedArgs, 'id' | 'subgraphError'>>;
  proxyCreateds?: SubscriptionResolver<Array<ResolversTypes['ProxyCreated']>, "proxyCreateds", ParentType, ContextType, RequireFields<SubscriptionproxyCreatedsArgs, 'skip' | 'first' | 'subgraphError'>>;
  orderCreated?: SubscriptionResolver<Maybe<ResolversTypes['OrderCreated']>, "orderCreated", ParentType, ContextType, RequireFields<SubscriptionorderCreatedArgs, 'id' | 'subgraphError'>>;
  orderCreateds?: SubscriptionResolver<Array<ResolversTypes['OrderCreated']>, "orderCreateds", ParentType, ContextType, RequireFields<SubscriptionorderCreatedsArgs, 'skip' | 'first' | 'subgraphError'>>;
  loanCreated?: SubscriptionResolver<Maybe<ResolversTypes['LoanCreated']>, "loanCreated", ParentType, ContextType, RequireFields<SubscriptionloanCreatedArgs, 'id' | 'subgraphError'>>;
  loanCreateds?: SubscriptionResolver<Array<ResolversTypes['LoanCreated']>, "loanCreateds", ParentType, ContextType, RequireFields<SubscriptionloanCreatedsArgs, 'skip' | 'first' | 'subgraphError'>>;
  punk?: SubscriptionResolver<Maybe<ResolversTypes['Punk']>, "punk", ParentType, ContextType, RequireFields<SubscriptionpunkArgs, 'id' | 'subgraphError'>>;
  punks?: SubscriptionResolver<Array<ResolversTypes['Punk']>, "punks", ParentType, ContextType, RequireFields<SubscriptionpunksArgs, 'skip' | 'first' | 'subgraphError'>>;
  buyer?: SubscriptionResolver<Maybe<ResolversTypes['Buyer']>, "buyer", ParentType, ContextType, RequireFields<SubscriptionbuyerArgs, 'id' | 'subgraphError'>>;
  buyers?: SubscriptionResolver<Array<ResolversTypes['Buyer']>, "buyers", ParentType, ContextType, RequireFields<SubscriptionbuyersArgs, 'skip' | 'first' | 'subgraphError'>>;
  account?: SubscriptionResolver<Maybe<ResolversTypes['Account']>, "account", ParentType, ContextType, RequireFields<SubscriptionaccountArgs, 'id' | 'subgraphError'>>;
  accounts?: SubscriptionResolver<Array<ResolversTypes['Account']>, "accounts", ParentType, ContextType, RequireFields<SubscriptionaccountsArgs, 'skip' | 'first' | 'subgraphError'>>;
  totalCount?: SubscriptionResolver<Maybe<ResolversTypes['TotalCount']>, "totalCount", ParentType, ContextType, RequireFields<SubscriptiontotalCountArgs, 'id' | 'subgraphError'>>;
  totalCounts?: SubscriptionResolver<Array<ResolversTypes['TotalCount']>, "totalCounts", ParentType, ContextType, RequireFields<SubscriptiontotalCountsArgs, 'skip' | 'first' | 'subgraphError'>>;
  collection?: SubscriptionResolver<Maybe<ResolversTypes['Collection']>, "collection", ParentType, ContextType, RequireFields<SubscriptioncollectionArgs, 'id' | 'subgraphError'>>;
  collections?: SubscriptionResolver<Array<ResolversTypes['Collection']>, "collections", ParentType, ContextType, RequireFields<SubscriptioncollectionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  loan?: SubscriptionResolver<Maybe<ResolversTypes['Loan']>, "loan", ParentType, ContextType, RequireFields<SubscriptionloanArgs, 'id' | 'subgraphError'>>;
  loans?: SubscriptionResolver<Array<ResolversTypes['Loan']>, "loans", ParentType, ContextType, RequireFields<SubscriptionloansArgs, 'skip' | 'first' | 'subgraphError'>>;
  asset?: SubscriptionResolver<Maybe<ResolversTypes['Asset']>, "asset", ParentType, ContextType, RequireFields<SubscriptionassetArgs, 'id' | 'subgraphError'>>;
  assets?: SubscriptionResolver<Array<ResolversTypes['Asset']>, "assets", ParentType, ContextType, RequireFields<SubscriptionassetsArgs, 'skip' | 'first' | 'subgraphError'>>;
  order?: SubscriptionResolver<Maybe<ResolversTypes['Order']>, "order", ParentType, ContextType, RequireFields<SubscriptionorderArgs, 'id' | 'subgraphError'>>;
  orders?: SubscriptionResolver<Array<ResolversTypes['Order']>, "orders", ParentType, ContextType, RequireFields<SubscriptionordersArgs, 'skip' | 'first' | 'subgraphError'>>;
  bid?: SubscriptionResolver<Maybe<ResolversTypes['Bid']>, "bid", ParentType, ContextType, RequireFields<SubscriptionbidArgs, 'id' | 'subgraphError'>>;
  bids?: SubscriptionResolver<Array<ResolversTypes['Bid']>, "bids", ParentType, ContextType, RequireFields<SubscriptionbidsArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: SubscriptionResolver<Maybe<ResolversTypes['_Meta_']>, "_meta", ParentType, ContextType, Partial<Subscription_metaArgs>>;
}>;

export type SupplyResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Supply'] = ResolversParentTypes['Supply']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  onBehalfOf?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  underlyingAsset?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TotalCountResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['TotalCount'] = ResolversParentTypes['TotalCount']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UTokenBorrowResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['UTokenBorrow'] = ResolversParentTypes['UTokenBorrow']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  onBehalfOf?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  iniciator?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  underlyingAsset?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  borrowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UTokenRepayResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['UTokenRepay'] = ResolversParentTypes['UTokenRepay']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  iniciator?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  loanId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  underlyingAsset?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  onBehalfOf?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  borrowRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WithdrawResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Withdraw'] = ResolversParentTypes['Withdraw']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  underlyingAsset?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  blockTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Block_Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_Block_'] = ResolversParentTypes['_Block_']> = ResolversObject<{
  hash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Meta_Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_Meta_'] = ResolversParentTypes['_Meta_']> = ResolversObject<{
  block?: Resolver<ResolversTypes['_Block_'], ParentType, ContextType>;
  deployment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasIndexingErrors?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = MeshContext> = ResolversObject<{
  Account?: AccountResolvers<ContextType>;
  ActiveVault?: ActiveVaultResolvers<ContextType>;
  AddCollateral?: AddCollateralResolvers<ContextType>;
  Asset?: AssetResolvers<ContextType>;
  AuctionBid?: AuctionBidResolvers<ContextType>;
  AuctionFinalize?: AuctionFinalizeResolvers<ContextType>;
  AuctionOrderRedeemed?: AuctionOrderRedeemedResolvers<ContextType>;
  AuctionRedeem?: AuctionRedeemResolvers<ContextType>;
  Bid?: BidResolvers<ContextType>;
  BigDecimal?: GraphQLScalarType;
  BigInt?: GraphQLScalarType;
  Borrow?: BorrowResolvers<ContextType>;
  Buyer?: BuyerResolvers<ContextType>;
  Bytes?: GraphQLScalarType;
  Collection?: CollectionResolvers<ContextType>;
  ForceSold?: ForceSoldResolvers<ContextType>;
  FrozenVault?: FrozenVaultResolvers<ContextType>;
  Int8?: GraphQLScalarType;
  Loan?: LoanResolvers<ContextType>;
  LoanCreated?: LoanCreatedResolvers<ContextType>;
  MarketBid?: MarketBidResolvers<ContextType>;
  MarketBuyNow?: MarketBuyNowResolvers<ContextType>;
  MarketClaim?: MarketClaimResolvers<ContextType>;
  MarketCreated?: MarketCreatedResolvers<ContextType>;
  Order?: OrderResolvers<ContextType>;
  OrderCreated?: OrderCreatedResolvers<ContextType>;
  PausedVault?: PausedVaultResolvers<ContextType>;
  ProxyCreated?: ProxyCreatedResolvers<ContextType>;
  Punk?: PunkResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Repay?: RepayResolvers<ContextType>;
  Sold?: SoldResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  Supply?: SupplyResolvers<ContextType>;
  TotalCount?: TotalCountResolvers<ContextType>;
  UTokenBorrow?: UTokenBorrowResolvers<ContextType>;
  UTokenRepay?: UTokenRepayResolvers<ContextType>;
  Withdraw?: WithdrawResolvers<ContextType>;
  _Block_?: _Block_Resolvers<ContextType>;
  _Meta_?: _Meta_Resolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = MeshContext> = ResolversObject<{
  entity?: entityDirectiveResolver<any, any, ContextType>;
  subgraphId?: subgraphIdDirectiveResolver<any, any, ContextType>;
  derivedFrom?: derivedFromDirectiveResolver<any, any, ContextType>;
}>;

export type MeshContext = Unlockdv2SepoliTypes.Context & BaseMeshContext;


import { fileURLToPath } from '@graphql-mesh/utils';
const baseDir = pathModule.join(pathModule.dirname(fileURLToPath(import.meta.url)), '..');

const importFn: ImportFn = <T>(moduleId: string) => {
  const relativeModuleId = (pathModule.isAbsolute(moduleId) ? pathModule.relative(baseDir, moduleId) : moduleId).split('\\').join('/').replace(baseDir + '/', '');
  switch(relativeModuleId) {
    case ".graphclient/sources/unlockdv2_sepoli/introspectionSchema":
      return Promise.resolve(importedModule$0) as T;
    
    default:
      return Promise.reject(new Error(`Cannot find module '${relativeModuleId}'.`));
  }
};

const rootStore = new MeshStore('.graphclient', new FsStoreStorageAdapter({
  cwd: baseDir,
  importFn,
  fileType: "ts",
}), {
  readonly: true,
  validate: false
});

export const rawServeConfig: YamlConfig.Config['serve'] = undefined as any
export async function getMeshOptions(): Promise<GetMeshOptions> {
const pubsub = new PubSub();
const sourcesStore = rootStore.child('sources');
const logger = new DefaultLogger("GraphClient");
const cache = new (MeshCache as any)({
      ...({} as any),
      importFn,
      store: rootStore.child('cache'),
      pubsub,
      logger,
    } as any)

const sources: MeshResolvedSource[] = [];
const transforms: MeshTransform[] = [];
const additionalEnvelopPlugins: MeshPlugin<any>[] = [];
const unlockdv2SepoliTransforms = [];
const additionalTypeDefs = [] as any[];
const unlockdv2SepoliHandler = new GraphqlHandler({
              name: "unlockdv2_sepoli",
              config: {"endpoint":"https://subgraph.satsuma-prod.com/bb7d5107614b/unlockd/unlockdv2-mainnet-sub/api","operationHeaders":{"x-api-key":null}},
              baseDir,
              cache,
              pubsub,
              store: sourcesStore.child("unlockdv2_sepoli"),
              logger: logger.child("unlockdv2_sepoli"),
              importFn,
            });
sources[0] = {
          name: 'unlockdv2_sepoli',
          handler: unlockdv2SepoliHandler,
          transforms: unlockdv2SepoliTransforms
        }
const additionalResolvers = [] as any[]
const merger = new(BareMerger as any)({
        cache,
        pubsub,
        logger: logger.child('bareMerger'),
        store: rootStore.child('bareMerger')
      })

  return {
    sources,
    transforms,
    additionalTypeDefs,
    additionalResolvers,
    cache,
    pubsub,
    merger,
    logger,
    additionalEnvelopPlugins,
    get documents() {
      return [
      {
        document: GetAllPunksDocument,
        get rawSDL() {
          return printWithCache(GetAllPunksDocument);
        },
        location: 'GetAllPunksDocument.graphql'
      },{
        document: GetClaimedAuctionsDocument,
        get rawSDL() {
          return printWithCache(GetClaimedAuctionsDocument);
        },
        location: 'GetClaimedAuctionsDocument.graphql'
      },{
        document: GetLoanByIdDocument,
        get rawSDL() {
          return printWithCache(GetLoanByIdDocument);
        },
        location: 'GetLoanByIdDocument.graphql'
      },{
        document: GetLoanFromNftDocument,
        get rawSDL() {
          return printWithCache(GetLoanFromNftDocument);
        },
        location: 'GetLoanFromNftDocument.graphql'
      },{
        document: GetLoansFromAssetIdsDocument,
        get rawSDL() {
          return printWithCache(GetLoansFromAssetIdsDocument);
        },
        location: 'GetLoansFromAssetIdsDocument.graphql'
      },{
        document: GetLoansOfAddressDocument,
        get rawSDL() {
          return printWithCache(GetLoansOfAddressDocument);
        },
        location: 'GetLoansOfAddressDocument.graphql'
      },{
        document: GetMarketItemDocument,
        get rawSDL() {
          return printWithCache(GetMarketItemDocument);
        },
        location: 'GetMarketItemDocument.graphql'
      },{
        document: GetMarketItemIdDocument,
        get rawSDL() {
          return printWithCache(GetMarketItemIdDocument);
        },
        location: 'GetMarketItemIdDocument.graphql'
      },{
        document: GetMarketItemsDocument,
        get rawSDL() {
          return printWithCache(GetMarketItemsDocument);
        },
        location: 'GetMarketItemsDocument.graphql'
      },{
        document: GetMarketItemsFromIdsDocument,
        get rawSDL() {
          return printWithCache(GetMarketItemsFromIdsDocument);
        },
        location: 'GetMarketItemsFromIdsDocument.graphql'
      },{
        document: GetMarketItemsFromNftsDocument,
        get rawSDL() {
          return printWithCache(GetMarketItemsFromNftsDocument);
        },
        location: 'GetMarketItemsFromNftsDocument.graphql'
      },{
        document: GetMyActivityDocument,
        get rawSDL() {
          return printWithCache(GetMyActivityDocument);
        },
        location: 'GetMyActivityDocument.graphql'
      },{
        document: GetOnGoingAuctionsDocument,
        get rawSDL() {
          return printWithCache(GetOnGoingAuctionsDocument);
        },
        location: 'GetOnGoingAuctionsDocument.graphql'
      },{
        document: GetPunksOfOwnerDocument,
        get rawSDL() {
          return printWithCache(GetPunksOfOwnerDocument);
        },
        location: 'GetPunksOfOwnerDocument.graphql'
      },{
        document: GetTotalCountDocument,
        get rawSDL() {
          return printWithCache(GetTotalCountDocument);
        },
        location: 'GetTotalCountDocument.graphql'
      },{
        document: GetTransactionHistoryDocument,
        get rawSDL() {
          return printWithCache(GetTransactionHistoryDocument);
        },
        location: 'GetTransactionHistoryDocument.graphql'
      }
    ];
    },
    fetchFn,
  };
}

export function createBuiltMeshHTTPHandler<TServerContext = {}>(): MeshHTTPHandler<TServerContext> {
  return createMeshHTTPHandler<TServerContext>({
    baseDir,
    getBuiltMesh: getBuiltGraphClient,
    rawServeConfig: undefined,
  })
}


let meshInstance$: Promise<MeshInstance> | undefined;

export function getBuiltGraphClient(): Promise<MeshInstance> {
  if (meshInstance$ == null) {
    meshInstance$ = getMeshOptions().then(meshOptions => getMesh(meshOptions)).then(mesh => {
      const id = mesh.pubsub.subscribe('destroy', () => {
        meshInstance$ = undefined;
        mesh.pubsub.unsubscribe(id);
      });
      return mesh;
    });
  }
  return meshInstance$;
}

export const execute: ExecuteMeshFn = (...args) => getBuiltGraphClient().then(({ execute }) => execute(...args));

export const subscribe: SubscribeMeshFn = (...args) => getBuiltGraphClient().then(({ subscribe }) => subscribe(...args));
export function getBuiltGraphSDK<TGlobalContext = any, TOperationContext = any>(globalContext?: TGlobalContext) {
  const sdkRequester$ = getBuiltGraphClient().then(({ sdkRequesterFactory }) => sdkRequesterFactory(globalContext));
  return getSdk<TOperationContext, TGlobalContext>((...args) => sdkRequester$.then(sdkRequester => sdkRequester(...args)));
}
export type getAllPunksQueryVariables = Exact<{ [key: string]: never; }>;


export type getAllPunksQuery = { punks: Array<Pick<Punk, 'id'>> };

export type getClaimedAuctionsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  user: Scalars['Bytes'];
}>;


export type getClaimedAuctionsQuery = { orders: Array<Pick<Order, 'id'>> };

export type getLoanByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type getLoanByIdQuery = { loan?: Maybe<(
    Pick<Loan, 'id' | 'underlyingAsset' | 'status' | 'amount' | 'totalAssets'>
    & { user: Pick<Account, 'id'>, assets: Array<Pick<Asset, 'id' | 'collection' | 'tokenId'>> }
  )> };

export type getLoanFromNftQueryVariables = Exact<{
  collection: Scalars['Bytes'];
  tokenId: Scalars['BigInt'];
}>;


export type getLoanFromNftQuery = { assets: Array<{ loan: (
      Pick<Loan, 'id' | 'underlyingAsset' | 'status' | 'amount' | 'totalAssets'>
      & { user: Pick<Account, 'id'>, assets: Array<Pick<Asset, 'id' | 'collection' | 'tokenId'>> }
    ) }> };

export type getLoansFromAssetIdsQueryVariables = Exact<{
  assetIds: Array<Scalars['ID']> | Scalars['ID'];
  status: Scalars['BigInt'];
}>;


export type getLoansFromAssetIdsQuery = { assets: Array<(
    Pick<Asset, 'id'>
    & { loan: (
      Pick<Loan, 'id' | 'underlyingAsset' | 'status' | 'amount' | 'totalAssets'>
      & { user: Pick<Account, 'id'>, assets: Array<Pick<Asset, 'id' | 'collection' | 'tokenId'>> }
    ) }
  )> };

export type getLoansOfAddressQueryVariables = Exact<{
  user: Scalars['Bytes'];
  status?: InputMaybe<Scalars['BigInt']>;
}>;


export type getLoansOfAddressQuery = { accounts: Array<{ borrows: Array<(
      Pick<Loan, 'id' | 'underlyingAsset' | 'status' | 'amount' | 'totalAssets'>
      & { user: Pick<Account, 'id'>, assets: Array<Pick<Asset, 'id' | 'collection' | 'tokenId'>> }
    )> }> };

export type getMarketItemQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type getMarketItemQuery = { order?: Maybe<(
    Pick<Order, 'debtToSell' | 'endAmount' | 'assetId' | 'endTime' | 'date' | 'collection' | 'tokenId' | 'transactionHash' | 'id' | 'loanId' | 'orderType' | 'seller' | 'startAmount' | 'status' | 'startTime' | 'buyer' | 'buyerAmount' | 'bidAmount' | 'bidder'>
    & { bids: Array<Pick<Bid, 'bidAmount' | 'bidder' | 'amountOfDebt' | 'amountToPay'>>, loan: (
      Pick<Loan, 'id' | 'underlyingAsset' | 'status'>
      & { assets: Array<Pick<Asset, 'collection' | 'tokenId'>> }
    ) }
  )> };

export type getMarketItemIdQueryVariables = Exact<{
  assetId: Scalars['Bytes'];
  status: Scalars['BigInt'];
  endTime: Scalars['BigInt'];
}>;


export type getMarketItemIdQuery = { orders: Array<Pick<Order, 'assetId' | 'id' | 'orderType' | 'date' | 'debtToSell' | 'endTime' | 'status'>> };

export type getMarketItemsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  status: Scalars['BigInt'];
}>;


export type getMarketItemsQuery = { orders: Array<(
    Pick<Order, 'debtToSell' | 'assetId' | 'endAmount' | 'endTime' | 'date' | 'id' | 'loanId' | 'collection' | 'tokenId' | 'orderType' | 'seller' | 'status' | 'startAmount' | 'startTime' | 'bidder' | 'bidAmount' | 'transactionHash' | 'buyer' | 'buyerAmount'>
    & { bids: Array<Pick<Bid, 'amountOfDebt' | 'amountToPay' | 'bidder'>>, loan: (
      Pick<Loan, 'id' | 'underlyingAsset' | 'status'>
      & { assets: Array<Pick<Asset, 'collection' | 'tokenId'>> }
    ) }
  )> };

export type getMarketItemsFromIdsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  loanIds: Array<Scalars['Bytes']> | Scalars['Bytes'];
}>;


export type getMarketItemsFromIdsQuery = { orders: Array<(
    Pick<Order, 'debtToSell' | 'endAmount' | 'assetId' | 'endTime' | 'date' | 'collection' | 'transactionHash' | 'tokenId' | 'id' | 'loanId' | 'orderType' | 'seller' | 'startAmount' | 'status' | 'startTime' | 'buyer' | 'buyerAmount' | 'bidAmount' | 'bidder'>
    & { bids: Array<Pick<Bid, 'bidAmount' | 'bidder' | 'amountOfDebt' | 'amountToPay'>>, loan: (
      Pick<Loan, 'id' | 'underlyingAsset' | 'status'>
      & { assets: Array<Pick<Asset, 'collection' | 'tokenId'>> }
    ) }
  )> };

export type getMarketItemsFromNftsQueryVariables = Exact<{
  assetIds: Array<Scalars['Bytes']> | Scalars['Bytes'];
}>;


export type getMarketItemsFromNftsQuery = { orders: Array<(
    Pick<Order, 'debtToSell' | 'endAmount' | 'assetId' | 'endTime' | 'date' | 'collection' | 'transactionHash' | 'tokenId' | 'id' | 'loanId' | 'orderType' | 'seller' | 'startAmount' | 'status' | 'startTime' | 'buyer' | 'buyerAmount' | 'bidAmount' | 'bidder'>
    & { bids: Array<Pick<Bid, 'bidAmount' | 'bidder' | 'amountOfDebt' | 'amountToPay'>>, loan: (
      Pick<Loan, 'id' | 'underlyingAsset' | 'status'>
      & { assets: Array<Pick<Asset, 'collection' | 'tokenId'>> }
    ) }
  )> };

export type getMyActivityQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  user: Scalars['Bytes'];
}>;


export type getMyActivityQuery = { orders: Array<(
    Pick<Order, 'id' | 'status' | 'orderType' | 'assetId' | 'date' | 'loanId' | 'seller' | 'startAmount' | 'endAmount' | 'startTime' | 'endTime' | 'debtToSell' | 'bidder' | 'bidAmount' | 'buyer' | 'buyerAmount' | 'collection' | 'transactionHash' | 'tokenId'>
    & { bids: Array<Pick<Bid, 'amountOfDebt' | 'amountToPay' | 'bidAmount' | 'bidder'>>, loan: (
      Pick<Loan, 'id' | 'underlyingAsset' | 'status'>
      & { assets: Array<Pick<Asset, 'collection' | 'tokenId'>> }
    ) }
  )> };

export type getOnGoingAuctionsQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
  user: Scalars['Bytes'];
}>;


export type getOnGoingAuctionsQuery = { orders: Array<Pick<Order, 'id'>> };

export type getPunksOfOwnerQueryVariables = Exact<{
  owner: Scalars['Bytes'];
}>;


export type getPunksOfOwnerQuery = { punks: Array<Pick<Punk, 'id'>> };

export type getTotalCountQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type getTotalCountQuery = { totalCount?: Maybe<Pick<TotalCount, 'totalCount'>> };

export type getTransactionHistoryQueryVariables = Exact<{
  first: Scalars['Int'];
  skip: Scalars['Int'];
}>;


export type getTransactionHistoryQuery = { orders: Array<(
    Pick<Order, 'id' | 'status' | 'orderType' | 'loanId' | 'seller' | 'date' | 'startAmount' | 'endAmount' | 'startTime' | 'endTime' | 'debtToSell' | 'assetId' | 'bidder' | 'bidAmount' | 'lastBidder' | 'lastBidAmount' | 'transactionHash' | 'buyer' | 'buyerAmount' | 'collection' | 'tokenId'>
    & { bids: Array<Pick<Bid, 'amountOfDebt' | 'amountToPay' | 'bidAmount' | 'bidder'>>, loan: (
      Pick<Loan, 'id' | 'underlyingAsset' | 'amount' | 'status'>
      & { user: Pick<Account, 'id' | 'amountBorrowed' | 'amountDeposited'>, assets: Array<Pick<Asset, 'id' | 'collection' | 'tokenId'>> }
    ) }
  )> };


export const getAllPunksDocument = gql`
    query getAllPunks {
  punks {
    id
  }
}
    ` as unknown as DocumentNode<getAllPunksQuery, getAllPunksQueryVariables>;
export const getClaimedAuctionsDocument = gql`
    query getClaimedAuctions($first: Int!, $skip: Int!, $user: Bytes!) {
  orders(
    first: $first
    skip: $skip
    where: {and: [{lastBidder: $user}, {status: "4"}]}
  ) {
    id
  }
}
    ` as unknown as DocumentNode<getClaimedAuctionsQuery, getClaimedAuctionsQueryVariables>;
export const getLoanByIdDocument = gql`
    query getLoanById($id: ID!) {
  loan(id: $id) {
    id
    underlyingAsset
    status
    amount
    user {
      id
    }
    totalAssets
    assets {
      id
      collection
      tokenId
    }
  }
}
    ` as unknown as DocumentNode<getLoanByIdQuery, getLoanByIdQueryVariables>;
export const getLoanFromNftDocument = gql`
    query getLoanFromNft($collection: Bytes!, $tokenId: BigInt!) {
  assets(where: {collection: $collection, tokenId: $tokenId}) {
    loan {
      id
      underlyingAsset
      status
      amount
      user {
        id
      }
      totalAssets
      assets {
        id
        collection
        tokenId
      }
    }
  }
}
    ` as unknown as DocumentNode<getLoanFromNftQuery, getLoanFromNftQueryVariables>;
export const getLoansFromAssetIdsDocument = gql`
    query getLoansFromAssetIds($assetIds: [ID!]!, $status: BigInt!) {
  assets(where: {id_in: $assetIds, loan_: {status: $status}}) {
    id
    loan {
      id
      underlyingAsset
      status
      amount
      user {
        id
      }
      totalAssets
      assets {
        id
        collection
        tokenId
      }
    }
  }
}
    ` as unknown as DocumentNode<getLoansFromAssetIdsQuery, getLoansFromAssetIdsQueryVariables>;
export const getLoansOfAddressDocument = gql`
    query getLoansOfAddress($user: Bytes!, $status: BigInt) {
  accounts(where: {user: $user}) {
    borrows(where: {status: $status}) {
      id
      underlyingAsset
      status
      amount
      user {
        id
      }
      totalAssets
      assets {
        id
        collection
        tokenId
      }
    }
  }
}
    ` as unknown as DocumentNode<getLoansOfAddressQuery, getLoansOfAddressQueryVariables>;
export const getMarketItemDocument = gql`
    query getMarketItem($id: ID!) {
  order(id: $id) {
    debtToSell
    endAmount
    assetId
    endTime
    date
    collection
    tokenId
    transactionHash
    id
    bids {
      bidAmount
      bidder
      amountOfDebt
      amountToPay
    }
    loan {
      id
      underlyingAsset
      status
      assets {
        collection
        tokenId
      }
    }
    loanId
    orderType
    seller
    startAmount
    status
    startTime
    buyer
    buyerAmount
    bidAmount
    bidder
  }
}
    ` as unknown as DocumentNode<getMarketItemQuery, getMarketItemQueryVariables>;
export const getMarketItemIdDocument = gql`
    query getMarketItemId($assetId: Bytes!, $status: BigInt!, $endTime: BigInt!) {
  orders(where: {assetId: $assetId, status: $status, endTime_gt: $endTime}) {
    assetId
    id
    orderType
    date
    debtToSell
    endTime
    status
  }
}
    ` as unknown as DocumentNode<getMarketItemIdQuery, getMarketItemIdQueryVariables>;
export const getMarketItemsDocument = gql`
    query getMarketItems($limit: Int!, $offset: Int!, $status: BigInt!) {
  orders(first: $limit, skip: $offset, where: {status: $status}) {
    debtToSell
    assetId
    endAmount
    endTime
    date
    id
    loanId
    collection
    tokenId
    orderType
    seller
    status
    startAmount
    startTime
    bidder
    bidAmount
    transactionHash
    bids {
      amountOfDebt
      amountToPay
      bidder
    }
    loan {
      id
      underlyingAsset
      status
      assets {
        collection
        tokenId
      }
    }
    buyer
    buyerAmount
  }
}
    ` as unknown as DocumentNode<getMarketItemsQuery, getMarketItemsQueryVariables>;
export const getMarketItemsFromIdsDocument = gql`
    query getMarketItemsFromIds($first: Int!, $skip: Int!, $loanIds: [Bytes!]!) {
  orders(first: $first, skip: $skip, where: {status: "0", loanId_in: $loanIds}) {
    debtToSell
    endAmount
    assetId
    endTime
    date
    collection
    transactionHash
    tokenId
    id
    bids {
      bidAmount
      bidder
      amountOfDebt
      amountToPay
    }
    loan {
      id
      underlyingAsset
      status
      assets {
        collection
        tokenId
      }
    }
    loanId
    orderType
    seller
    startAmount
    status
    startTime
    buyer
    buyerAmount
    bidAmount
    bidder
  }
}
    ` as unknown as DocumentNode<getMarketItemsFromIdsQuery, getMarketItemsFromIdsQueryVariables>;
export const getMarketItemsFromNftsDocument = gql`
    query getMarketItemsFromNfts($assetIds: [Bytes!]!) {
  orders(first: 1000, where: {status: "0", assetId_in: $assetIds}) {
    debtToSell
    endAmount
    assetId
    endTime
    date
    collection
    transactionHash
    tokenId
    id
    bids {
      bidAmount
      bidder
      amountOfDebt
      amountToPay
    }
    loan {
      id
      underlyingAsset
      status
      assets {
        collection
        tokenId
      }
    }
    loanId
    orderType
    seller
    startAmount
    status
    startTime
    buyer
    buyerAmount
    bidAmount
    bidder
  }
}
    ` as unknown as DocumentNode<getMarketItemsFromNftsQuery, getMarketItemsFromNftsQueryVariables>;
export const getMyActivityDocument = gql`
    query getMyActivity($first: Int!, $skip: Int!, $user: Bytes!) {
  orders(
    first: $first
    skip: $skip
    where: {or: [{bids_: {bidder: $user}}, {buyer: $user}]}
    orderBy: date
    orderDirection: desc
  ) {
    id
    status
    orderType
    assetId
    date
    loanId
    seller
    startAmount
    endAmount
    startTime
    endTime
    debtToSell
    bidder
    bidAmount
    buyer
    buyerAmount
    collection
    transactionHash
    tokenId
    bids {
      amountOfDebt
      amountToPay
      bidAmount
      bidder
    }
    loan {
      id
      underlyingAsset
      status
      assets {
        collection
        tokenId
      }
    }
  }
}
    ` as unknown as DocumentNode<getMyActivityQuery, getMyActivityQueryVariables>;
export const getOnGoingAuctionsDocument = gql`
    query getOnGoingAuctions($first: Int!, $skip: Int!, $user: Bytes!) {
  orders(
    first: $first
    skip: $skip
    where: {and: [{lastBidder: $user}, {status: "0"}]}
  ) {
    id
  }
}
    ` as unknown as DocumentNode<getOnGoingAuctionsQuery, getOnGoingAuctionsQueryVariables>;
export const getPunksOfOwnerDocument = gql`
    query getPunksOfOwner($owner: Bytes!) {
  punks(where: {owner: $owner}) {
    id
  }
}
    ` as unknown as DocumentNode<getPunksOfOwnerQuery, getPunksOfOwnerQueryVariables>;
export const getTotalCountDocument = gql`
    query getTotalCount($id: ID!) {
  totalCount(id: $id) {
    totalCount
  }
}
    ` as unknown as DocumentNode<getTotalCountQuery, getTotalCountQueryVariables>;
export const getTransactionHistoryDocument = gql`
    query getTransactionHistory($first: Int!, $skip: Int!) {
  orders(
    first: $first
    skip: $skip
    where: {status_in: ["3", "4"]}
    orderBy: date
    orderDirection: desc
  ) {
    id
    status
    orderType
    loanId
    seller
    date
    startAmount
    endAmount
    startTime
    endTime
    debtToSell
    assetId
    bidder
    bidAmount
    lastBidder
    lastBidAmount
    transactionHash
    buyer
    buyerAmount
    collection
    tokenId
    bids {
      amountOfDebt
      amountToPay
      bidAmount
      bidder
    }
    loan {
      id
      underlyingAsset
      amount
      user {
        id
        amountBorrowed
        amountDeposited
      }
      status
      assets {
        id
        collection
        tokenId
      }
    }
  }
}
    ` as unknown as DocumentNode<getTransactionHistoryQuery, getTransactionHistoryQueryVariables>;

















export type Requester<C = {}, E = unknown> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    getAllPunks(variables?: getAllPunksQueryVariables, options?: C): Promise<getAllPunksQuery> {
      return requester<getAllPunksQuery, getAllPunksQueryVariables>(getAllPunksDocument, variables, options) as Promise<getAllPunksQuery>;
    },
    getClaimedAuctions(variables: getClaimedAuctionsQueryVariables, options?: C): Promise<getClaimedAuctionsQuery> {
      return requester<getClaimedAuctionsQuery, getClaimedAuctionsQueryVariables>(getClaimedAuctionsDocument, variables, options) as Promise<getClaimedAuctionsQuery>;
    },
    getLoanById(variables: getLoanByIdQueryVariables, options?: C): Promise<getLoanByIdQuery> {
      return requester<getLoanByIdQuery, getLoanByIdQueryVariables>(getLoanByIdDocument, variables, options) as Promise<getLoanByIdQuery>;
    },
    getLoanFromNft(variables: getLoanFromNftQueryVariables, options?: C): Promise<getLoanFromNftQuery> {
      return requester<getLoanFromNftQuery, getLoanFromNftQueryVariables>(getLoanFromNftDocument, variables, options) as Promise<getLoanFromNftQuery>;
    },
    getLoansFromAssetIds(variables: getLoansFromAssetIdsQueryVariables, options?: C): Promise<getLoansFromAssetIdsQuery> {
      return requester<getLoansFromAssetIdsQuery, getLoansFromAssetIdsQueryVariables>(getLoansFromAssetIdsDocument, variables, options) as Promise<getLoansFromAssetIdsQuery>;
    },
    getLoansOfAddress(variables: getLoansOfAddressQueryVariables, options?: C): Promise<getLoansOfAddressQuery> {
      return requester<getLoansOfAddressQuery, getLoansOfAddressQueryVariables>(getLoansOfAddressDocument, variables, options) as Promise<getLoansOfAddressQuery>;
    },
    getMarketItem(variables: getMarketItemQueryVariables, options?: C): Promise<getMarketItemQuery> {
      return requester<getMarketItemQuery, getMarketItemQueryVariables>(getMarketItemDocument, variables, options) as Promise<getMarketItemQuery>;
    },
    getMarketItemId(variables: getMarketItemIdQueryVariables, options?: C): Promise<getMarketItemIdQuery> {
      return requester<getMarketItemIdQuery, getMarketItemIdQueryVariables>(getMarketItemIdDocument, variables, options) as Promise<getMarketItemIdQuery>;
    },
    getMarketItems(variables: getMarketItemsQueryVariables, options?: C): Promise<getMarketItemsQuery> {
      return requester<getMarketItemsQuery, getMarketItemsQueryVariables>(getMarketItemsDocument, variables, options) as Promise<getMarketItemsQuery>;
    },
    getMarketItemsFromIds(variables: getMarketItemsFromIdsQueryVariables, options?: C): Promise<getMarketItemsFromIdsQuery> {
      return requester<getMarketItemsFromIdsQuery, getMarketItemsFromIdsQueryVariables>(getMarketItemsFromIdsDocument, variables, options) as Promise<getMarketItemsFromIdsQuery>;
    },
    getMarketItemsFromNfts(variables: getMarketItemsFromNftsQueryVariables, options?: C): Promise<getMarketItemsFromNftsQuery> {
      return requester<getMarketItemsFromNftsQuery, getMarketItemsFromNftsQueryVariables>(getMarketItemsFromNftsDocument, variables, options) as Promise<getMarketItemsFromNftsQuery>;
    },
    getMyActivity(variables: getMyActivityQueryVariables, options?: C): Promise<getMyActivityQuery> {
      return requester<getMyActivityQuery, getMyActivityQueryVariables>(getMyActivityDocument, variables, options) as Promise<getMyActivityQuery>;
    },
    getOnGoingAuctions(variables: getOnGoingAuctionsQueryVariables, options?: C): Promise<getOnGoingAuctionsQuery> {
      return requester<getOnGoingAuctionsQuery, getOnGoingAuctionsQueryVariables>(getOnGoingAuctionsDocument, variables, options) as Promise<getOnGoingAuctionsQuery>;
    },
    getPunksOfOwner(variables: getPunksOfOwnerQueryVariables, options?: C): Promise<getPunksOfOwnerQuery> {
      return requester<getPunksOfOwnerQuery, getPunksOfOwnerQueryVariables>(getPunksOfOwnerDocument, variables, options) as Promise<getPunksOfOwnerQuery>;
    },
    getTotalCount(variables: getTotalCountQueryVariables, options?: C): Promise<getTotalCountQuery> {
      return requester<getTotalCountQuery, getTotalCountQueryVariables>(getTotalCountDocument, variables, options) as Promise<getTotalCountQuery>;
    },
    getTransactionHistory(variables: getTransactionHistoryQueryVariables, options?: C): Promise<getTransactionHistoryQuery> {
      return requester<getTransactionHistoryQuery, getTransactionHistoryQueryVariables>(getTransactionHistoryDocument, variables, options) as Promise<getTransactionHistoryQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;